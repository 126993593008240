.customer-phone-container {
    &.has-error {
        input {
            border-color: #e96161 !important;
        }
    }

    .customer-phone {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: 100%;
        cursor: text;

        .input-group-text {
            min-height: 34.3px;
            min-width: 34.3px;
            justify-content: center;
            border-color: #eeeeee;

            &:not(:last-child) {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        .input-container {
            flex: 1;

            input {
                padding: 5px 5px;
                border: 1px solid #eeeeee;
                border-radius: 0.475rem;
                min-height: 34.3px;
                width: 100%;

                &::placeholder {
                    color: #a1a5b7;
                    opacity: 1;
                }

                &:focus-visible,
                &:focus,
                &:visited {
                    outline: unset;
                    border-color: #b5b5c3 !important;
                }
            }

            &:not(:first-child) {
                input {
                    margin-left: -1px;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }
    }

    .suggest-list-container {
        position: relative;

        .suggest-list {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            display: flex;
            flex-direction: column;
            max-height: 150px;
            overflow: auto;
            border: 1px solid #f6f6f6;
            border-radius: 0 0 10px 10px;
            box-shadow: 6px 9px 18px -4px rgba(0, 0, 0, 0.29);
            -webkit-box-shadow: 6px 9px 18px -4px rgba(0, 0, 0, 0.29);
            -moz-box-shadow: 6px 9px 18px -4px rgba(0, 0, 0, 0.29);
            z-index: 999;
            background: white;

            .suggest-item {
                background: transparent;
                border: 0;
                padding: 0px 10px;
                padding: 8px 10px;

                .phone-label--options {
                    margin-bottom: 0;
                    color: #00171F !important;
                }
            }
        }
    }
}