.select-service1{
  width: 60%;
  display: flex !important;
  align-items: center;

  @media (max-width: 786px) {
    width: 80%;
  }

  .service-tree1{
    width: 100%;

    .dropdown-trigger{
      border: 0 !important;
    }
    .tag-list {
      padding: 0 !important;
      margin: 0 !important;
    }
    .dropdown {
      display: block !important;
      .dropdown-trigger{
        padding: 0 !important;
        display: block !important;
      }
    }
  }

  .dropdown-trigger::after{
    display: none;
  }
}
.select-service1 {
  display: block;
  width: 100%;
  font-weight: 500;
  line-height: 1.5;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #e4e6ef;
  appearance: none;
  border-radius: 0.475rem;
  
  .tag-list {
      padding: 0;
      margin: 0.95rem 0 0.25rem 0;
      .placeholder {
          color: #bac0c2 !important;
      }
      .tag-item {
          display: inline-block;
          margin-left: 4px;

          .tag {
              border-radius: 5px;
              background-color: #E7E7E7;
              border: 1px solid #DADADA;
              padding: 2px 6px;
              padding-right: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #00171F;
              .tag-remove {
                  padding: 0px 8px;
                  color: #00171F;
                  font-size: 75%;
                  cursor: pointer;
                  background-color: transparent;
                  border: none;
                  outline: none;
              }
          }

          &--active{
            & > .tag{
              background-color: #E7E7E7 !important;
              border: 1px solid #DADADA;
            }
          }
          &--inactive{
            & > .tag{
              background-color: #BAC0C2 !important;
              border: 1px solid #A5A8B6;
            }
          }
      }
  }
  .node.focused {
      background-color: #f4f4f4;
  }
  ul {
      list-style-type: none !important;
  }
  .dropdown-content {
      font-weight: 400;
      font-size: 14px;
      width: 100%;
      margin-top: 13px;
      border-radius: 5px;
      z-index: 9;
      position: absolute;
      padding: 4px;
      z-index: 1;
      background: #fff;
      border-top: 1px solid rgba(0,0,0,.05);
      box-shadow: 0 5px 8px rgb(0 0 0 / 15%);
      .search {
          height: 40px;
          width: 100%;
          border: none;
          border-bottom: 1px solid #ccc;
          outline: none;
      }
      ul {
          padding: 0;
          .infinite-scroll-component {
              max-height: 300px;
              overflow: scroll;
              
              .checkbox-item {
                  width: 17px;
                  height: 17px;
                  margin-left: 5px;
                  margin-right: 5px;
                  vertical-align: middle;
                  margin: 0 4px 0 0;
              }
              & > .node {
                &--active{

                }
                &--inactive{
                  background-color: #BAC0C2;
                }
              }
          }
          li {
              display: list-item;
              text-align: -webkit-match-parent;
              padding-left: 0;
              i {
                font-style: inherit !important;
              }
          }
          .toggle {
              font-size: 23px;
              margin-right: 5px;
              padding: 5px;
              color: #7d8699; 
          }
          .toggle.collapsed:after {
              content: "+";
          }
          .toggle.expanded:after {
              content: "-";
          }
          // .toggle.collapsed {
          //     writing-mode: vertical-lr;
          //     -webkit-transform: rotate(44deg);
          // }
      }
      .node {
          list-style: none;
          white-space: nowrap;
          padding: 4px;
      }
  }
}

.button-action-select-service{
  @media (max-width: 786px) {
    margin-top: 10px;
    display: flex;
  }
  button{
    @media (max-width: 786px) {
      margin-left: 0 !important;
      height: 40px !important;
      margin: 0 5px;
    }
  }
}