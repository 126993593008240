@import 'theme/assets/sass/components/variables.custom';

.warning-same-day {
  font-size: 17px;
  color: $primary;
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  .warning--icon {
    width: 40px;
    height: 40px;
    margin-right: 5px;
  }
}
