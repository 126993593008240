@import "theme/assets/sass/components/variables.custom";

.date-time-picker-inline-component {
    display: flex;
    flex-direction: row;
    $item-width: 30px;
    border-bottom: 1px solid #EEEEEE;

    @media all and (max-width: 1024px) {
        display: unset;

        .date-picker {
            float: left;
            width: 100%;
            text-align: center;
        }

        .time-picker {
            float: left;
            width: 40%;
            height: 500px;
        }

        .date-time-selected {
            float: left;
            width: 60%;
            height: 500px;
        }
    }

    @media all and (max-width: 425px) {

        .time-picker,
        .date-time-selected {
            width: 100%;
            height: 300px;
        }
    }

    .react-datepicker__day-names {
        .react-datepicker__day-name {
            margin: 0px;
            width: $item-width + 6 !important;
        }
    }
    .date-picker {
        // flex: .8
    }

    .react-datepicker {
        font-family: "Tajawal", sans-serif;
        border: 0;

        button.react-datepicker__navigation {
            margin-top: 8px;

            >span::before {
                border-color: #00171F;
            }
        }

        .react-datepicker__header {
            border: 0;
            border-radius: 0;
            background: transparent;
            padding: 0;

            .react-datepicker__current-month {
                line-height: 40px;
                text-transform: uppercase;
                font-size: 16px;
                font-weight: 500;
                border-top: 1px solid #EEEEEE;
            }

            .react-datepicker__day-names {

                .react-datepicker__day-name {
                    width: $item-width;
                    line-height: $item-width;
                    font-size: 10px;
                }
            }
        }

        .react-datepicker__month-container {
            .react-datepicker__current-month {
                background: rgba(238, 238, 238, 0.52);
            }
        }

        .react-datepicker__week {

            .react-datepicker__day {
                width: $item-width + 6;
                border: 1px solid transparent;
                line-height: $item-width;
                font-size: 15px;
                font-weight: 400;
                position: relative;
                background: transparent;
                z-index: 1;
                margin: 0px;

                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 30px;
                    height: 30px;
                    top: calc(50% - 15px);
                    left: calc(50% - 15px);
                    border-radius: 50%;
                    z-index: -1;
                }

                &.react-datepicker__day--outside-month {
                    // color: #AAAAAA;
                }

                &:hover:not(.react-datepicker__day--disabled) {
                    border-radius: 50%;
                    background: transparent;

                    &::before {
                        background: #f0f0f0;
                    }
                }

                &.react-datepicker__day--highlighted {
                    background: #FFF3CF !important;
                    border-radius: 0px;
                    color: #00171F;
                }

                &.react-datepicker__day--highlighted.react-datepicker__day--highlighted--last--vertical {
                    border-radius:  0% 0% 50% 50% ;
                }

                &.react-datepicker__day--selected:not(.react-datepicker__day--disabled).react-datepicker__day--highlighted.react-datepicker__day--selected--first {
                    border-radius: 50% 0% 0% 50%;
                    background:  #FFF3CF !important;
                }
                &.react-datepicker__day--selected:not(.react-datepicker__day--disabled).react-datepicker__day--highlighted.react-datepicker__day--selected--first--rtl {
                    border-radius:  0% 50% 50% 0% ;
                    background:  #FFF3CF !important;
                }

                &.react-datepicker__day--selected:not(.react-datepicker__day--disabled).react-datepicker__day--highlighted.react-datepicker__day--selected--vertical {
                    border-radius: 50% 50% 0% 0%;
                    background:  #FFF3CF !important;
                }

                &.react-datepicker__day--selected:not(.react-datepicker__day--disabled).react-datepicker__day--highlighted {
                    border-radius: 0px;
                    background:  #FFF3CF !important;
                }

                &.react-datepicker__day--selected--one:not(.react-datepicker__day--disabled).react-datepicker__day--highlighted {
                    border-radius: 0px;
                    background:  transparent !important;
                }

                &.react-datepicker__day--selected:not(.react-datepicker__day--disabled).react-datepicker__day--highlighted.react-datepicker__day--selected--last {
                    border-radius:  0% 50% 50% 0% ;
                    background:  #FFF3CF !important;
                }

                &.react-datepicker__day--selected:not(.react-datepicker__day--disabled).react-datepicker__day--highlighted.react-datepicker__day--selected--last--rtl {
                    border-radius: 50% 0% 0% 50%;
                    background:  #FFF3CF !important;
                }

                &.react-datepicker__day--selected:not(.react-datepicker__day--disabled).react-datepicker__day--showing{
                    &::before {
                        // border: 1px #00171F solid;
                    }
                }

                &.react-datepicker__day--selected:not(.react-datepicker__day--disabled) {
                    border-radius: 50%;
                    color: #00171F;

                    &::before {
                        background: $primary;
                    }
                }


                &.react-datepicker__day--keyboard-selected {
                    background: white;
                    border: 0;
                    color: #000;
                }

                &.react-datepicker__day--disabled {
                    color: #ccc !important;
                }

                &.react-datepicker__day--excluded {
                    color: #ccc;
                }

                &:focus-visible,
                &:focus,
                &:visited {
                    outline: unset;
                }
            }
        }
    }

}

.date-time-picker-inline:after {
    content: '';
    clear: both;
    display: block;
}