.step-2 {
    .worker-selection {
        &__item {
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid #eeeeee;

            &:last-child {
                border-bottom: 0;
            }
        }
    }
}

.assign-footer {
    background-color: #f6f6f6;

    .btn-back {
        background-color: #bac0c2;
        color: inherit !important;
    }
}
.update-loading {
    margin: auto;
}