@import "theme/assets/sass/components/variables.custom";

.date-time-picker-inline {
    display: flex;
    flex-direction: row;
    $item-width: 60px;
    border-bottom: 1px solid #EEEEEE;

    @media all and (max-width: 1024px) {
        display: unset;

        .date-picker {
            float: left;
            width: 100%;
            text-align: center;
        }

        .time-picker,
        .date-time-selected {
            float: left;
            width: 50%;
            height: 500px;
        }
    }

    @media only screen and (max-width: 1750px) and (min-width: 1440px) {
        display: flex;

        .date-picker {
            width: fit-content;

            .react-datepicker__day,
            .react-datepicker__day-name {
                width: 40px !important;
            }
        }

        .time-picker {
            .time-slot-item {
                padding: 10px 0 10px 0px !important;
            }
        }
    }

    @media only screen and (max-width: 1440px) and (min-width: 1200px) {
        display: flex;

        .date-picker {
            width: fit-content;

            .react-datepicker__day,
            .react-datepicker__day-name {
                width: 55px !important;
            }
        }

        .time-picker {
            .time-slot-item {
                padding: 10px 0 10px 0px !important;
            }
        }
    }

    @media only screen and (max-width: 1200px) and (min-width: 1000px) {
        display: flex;

        .date-picker {
            width: fit-content;

            .react-datepicker__day,
            .react-datepicker__day-name {
                width: 45px !important;
            }
        }

        .time-picker {
            .time-slot-item {
                padding: 10px 0 10px 0px !important;
            }
        }
    }

    @media only screen and (max-width: 1000px) and (min-width: 850px) {
        display: flex;

        .date-picker {
            width: fit-content;

            .react-datepicker__day,
            .react-datepicker__day-name {
                width: 40px !important;
            }
        }

        .time-picker {
            .time-slot-item {
                padding: 10px 0 10px 0px !important;
            }
        }
    }

    @media only screen and (device-width: 768px) {

        .time-picker,
        .date-time-selected {
            width: 100%;
            height: 300px;
        }
    }

    @media all and (max-width: 425px) {

        .time-picker,
        .date-time-selected {
            width: 100%;
            height: 300px;
        }
    }

    .date-picker {
        // flex: .8
    }

    .react-datepicker {
        // width: 100%;
        font-family: "Tajawal", sans-serif;
        border: 0;

        button.react-datepicker__navigation {
            margin-top: 8px;

            >span::before {
                border-color: #00171F;
            }
        }

        .react-datepicker__header {
            border: 0;
            border-radius: 0;
            background: transparent;
            padding: 0;

            .react-datepicker__current-month {
                background: #FFFFFF;
                line-height: 40px;
                text-transform: uppercase;
                font-size: 16px;
                font-weight: 500;
                border-top: 1px solid #EEEEEE;
            }

            .react-datepicker__day-names {

                .react-datepicker__day-name {
                    width: $item-width;
                    line-height: $item-width;
                    font-size: 16px;
                }
            }
        }

        .react-datepicker__month-container {}

        .react-datepicker__week {

            .react-datepicker__day {
                width: $item-width;
                line-height: $item-width;
                font-size: 15px;
                font-weight: 400;
                position: relative;
                background: transparent;
                z-index: 1;

                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 30px;
                    height: 30px;
                    top: calc(50% - 15px);
                    left: calc(50% - 15px);
                    border-radius: 50%;
                    z-index: -1;
                }

                &.react-datepicker__day--outside-month {
                    color: #AAAAAA;
                }

                &:hover:not(.react-datepicker__day--disabled) {
                    border-radius: 50%;
                    background: transparent;

                    &::before {
                        background: #f0f0f0;
                    }
                }

                &.react-datepicker__day--highlighted {
                    background: #FFF3CF;
                    color: #00171F;
                }

                &.react-datepicker__day--selected:not(.react-datepicker__day--disabled) {
                    border-radius: 50%;
                    color: #00171F;

                    &::before {
                        background: $primary;
                    }
                }

                &.react-datepicker__day--current {
                    border: 1px #00171F solid;
                }

                &.react-datepicker__day--keyboard-selected {
                    background: white;
                    border: 0;
                    color: #000;
                }

                &.react-datepicker__day--disabled {
                    color: #ccc !important;
                }

                &.react-datepicker__day--excluded {
                    color: #ccc;
                }

                &:focus-visible,
                &:focus,
                &:visited {
                    outline: unset;
                }
            }
        }
    }

    .time-picker {
        flex: 1;
        display: flex;
        flex-direction: column;

        .time-title {
            background: #FFFFFF;
            line-height: 40px;
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            border: 1px solid #EEEEEE;
        }

        .time-slot-list-container {
            position: relative;
            flex: 1;

            .time-slot-list {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                overflow: auto;

                .time-slot-item {
                    // line-height: 40px;
                    width: 100%;
                    background: transparent;
                    border: 1px solid #EEEEEE;
                    padding: 10px 10px;
                    text-align: center;
                    border-top-width: 0;
                    display: flex;
                    flex-direction: row;

                    &.disabled {
                        background: #f8f8f8;
                        color: #bdbdbd;
                    }

                    &:last-child {
                        // border-bottom-width: 0;
                    }

                    &.active {
                        background: $primary;

                        .checker {
                            display: block;
                            opacity: 1;
                        }
                    }

                    p {
                        margin: 0;
                        text-transform: uppercase;
                        flex: 1;
                        justify-content: center;
                        align-items: center;
                        display: flex;
                    }

                    .checker {
                        background-image: url("data:image/svg+xml,%3Csvg id='Forms_Checkbox_Resting' data-name='Forms / Checkbox / Resting' xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 18 18'%3E%3Crect id='Rectangle_3' data-name='Rectangle 3' width='18' height='18' rx='9' fill='%2300171f'/%3E%3Cpath id='Shape' d='M3.158,6.8.136,3.775a.465.465,0,0,1,0-.657L.794,2.46a.465.465,0,0,1,.657,0L3.486,4.5,7.846.136a.465.465,0,0,1,.657,0l.657.657a.465.465,0,0,1,0,.657L3.815,6.8a.465.465,0,0,1-.657,0Z' transform='translate(4.667 5.534)' fill='%23fff'/%3E%3C/svg%3E%0A") !important;
                        height: 15px;
                        width: 15px;
                        margin-left: 10px;
                        // display: none;
                        opacity: 0;
                    }
                }
            }
        }
    }

    .date-time-selected {
        flex: 1;
        display: flex;
        flex-direction: column;

        .selected-title {
            display: flex;
            flex-direction: row;
            background: #EEEEEE;
            line-height: 40px;
        }

        .time-item,
        .date-item {
            text-align: center;
            flex: 1;
        }

        .selected-list-container {
            flex: 1;
            position: relative;

            .selected-list {
                position: absolute;
                overflow: auto;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }

            .selected-item {
                display: flex;
                flex-direction: row;
                border: 1px solid #EEEEEE;
                border-top: 0;
                line-height: 30px;

                .date-item {
                    border-right: 1px solid #EEEEEE;
                }

                .time-item,
                .date-item {
                    padding: 5px;
                    font-size: 12px;

                    .item-selected {
                        display: flex;
                        flex-direction: row;
                        background: $primary;
                        padding: 3px 5px;
                        border-radius: 20px;
                        justify-content: center;
                        text-transform: uppercase;

                        button {
                            background: transparent;
                            border: 0;
                            color: #00171F;
                            line-height: 20px;

                            i {
                                color: #00171F;
                            }
                        }
                    }

                }
            }
        }
    }
}

.date-time-picker-inline--package-flow {
    display: flex;
    flex-direction: row;
    $item-width: 35px;
    border-bottom: 1px solid #EEEEEE;

    @media all and (max-width: 1024px) {
        display: unset;

        .date-picker {
            float: left;
            width: 100%;
            text-align: center;
        }

        .time-picker,
        .date-time-selected {
            float: left;
            width: 50%;
            height: 500px;
        }
    }

    @media all and (max-width: 425px) {

        .time-picker,
        .date-time-selected {
            width: 100%;
            height: 300px;
        }
    }

    .date-picker {
        // flex: .8
    }

    .react-datepicker {
        // width: 100%;
        border: 0;
        font-family: "Tajawal", sans-serif;

        button.react-datepicker__navigation {
            margin-top: 8px;

            >span::before {
                border-color: #00171F;
            }
        }

        .react-datepicker__header {
            border: 0;
            border-radius: 0;
            background: transparent;
            padding: 0;

            .react-datepicker__current-month {
                background: #FFFFFF;
                line-height: 40px;
                text-transform: uppercase;
                font-size: 16px;
                font-weight: 500;
                border-top: 1px solid #EEEEEE;
            }

            .react-datepicker__day-names {

                .react-datepicker__day-name {
                    width: $item-width;
                    line-height: $item-width;
                    font-size: 16px;
                }
            }
        }

        .react-datepicker__month-container {}

        .react-datepicker__week {

            .react-datepicker__day {
                width: $item-width;
                line-height: $item-width;
                font-size: 15px;
                font-weight: 400;
                position: relative;
                background: transparent;
                z-index: 1;

                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 30px;
                    height: 30px;
                    top: calc(50% - 15px);
                    left: calc(50% - 15px);
                    border-radius: 50%;
                    z-index: -1;
                }

                &.react-datepicker__day--outside-month {
                    // color: #AAAAAA;
                }

                &:hover:not(.react-datepicker__day--disabled) {
                    border-radius: 50%;
                    background: transparent;

                    &::before {
                        background: #f0f0f0;
                    }
                }

                &.react-datepicker__day--highlighted {
                    background: #FFF3CF;
                    color: #00171F;
                }

                &.react-datepicker__day--selected:not(.react-datepicker__day--disabled) {
                    border-radius: 50%;
                    color: #00171F;

                    &::before {
                        background: $primary;
                    }
                }

                &.react-datepicker__day--showing {
                    border: 1px #00171F solid;
                }

                &.react-datepicker__day--keyboard-selected {
                    background: white;
                    border: 0;
                    color: #000;
                }

                &.react-datepicker__day--disabled {
                    color: #ccc !important;
                }

                &.react-datepicker__day--excluded {
                    color: #ccc;
                }

                &:focus-visible,
                &:focus,
                &:visited {
                    outline: unset;
                }
            }
        }
    }

    .time-picker {
        flex: 1;
        display: flex;
        flex-direction: column;

        .time-title {
            background: #FFFFFF;
            line-height: 40px;
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            border: 1px solid #EEEEEE;
        }

        .time-slot-list-container {
            position: relative;
            flex: 1;

            .time-slot-list {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                overflow: auto;

                .time-slot-item {
                    // line-height: 40px;
                    width: 100%;
                    background: transparent;
                    border: 1px solid #EEEEEE;
                    padding: 10px 10px;
                    text-align: center;
                    border-top-width: 0;
                    display: flex;
                    flex-direction: row;

                    &.disabled {
                        background: #f8f8f8;
                        color: #bdbdbd;
                    }

                    &:last-child {
                        // border-bottom-width: 0;
                    }

                    &.active {
                        background: $primary;

                        .checker {
                            display: block;
                            opacity: 1;
                        }
                    }

                    p {
                        margin: 0;
                        text-transform: uppercase;
                        flex: 1;
                        justify-content: center;
                        align-items: center;
                        display: flex;
                    }

                    .checker {
                        background-image: url("data:image/svg+xml,%3Csvg id='Forms_Checkbox_Resting' data-name='Forms / Checkbox / Resting' xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 18 18'%3E%3Crect id='Rectangle_3' data-name='Rectangle 3' width='18' height='18' rx='9' fill='%2300171f'/%3E%3Cpath id='Shape' d='M3.158,6.8.136,3.775a.465.465,0,0,1,0-.657L.794,2.46a.465.465,0,0,1,.657,0L3.486,4.5,7.846.136a.465.465,0,0,1,.657,0l.657.657a.465.465,0,0,1,0,.657L3.815,6.8a.465.465,0,0,1-.657,0Z' transform='translate(4.667 5.534)' fill='%23fff'/%3E%3C/svg%3E%0A") !important;
                        height: 15px;
                        width: 15px;
                        margin-left: 10px;
                        // display: none;
                        opacity: 0;
                    }
                }
            }
        }
    }

    .date-time-selected {
        flex: 1;
        display: flex;
        flex-direction: column;

        .selected-title {
            display: flex;
            flex-direction: row;
            background: #EEEEEE;
            line-height: 40px;
        }

        .time-item,
        .date-item {
            text-align: center;
            flex: 1;
        }

        .selected-list-container {
            flex: 1;
            position: relative;

            .selected-list {
                position: absolute;
                overflow: auto;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }

            .selected-item {
                display: flex;
                flex-direction: row;
                border: 1px solid #EEEEEE;
                border-top: 0;
                line-height: 30px;

                .date-item {
                    border-right: 1px solid #EEEEEE;
                }

                .time-item,
                .date-item {
                    padding: 5px;
                    font-size: 12px;

                    .item-selected {
                        display: flex;
                        flex-direction: row;
                        background: $primary;
                        padding: 3px 5px;
                        border-radius: 20px;
                        justify-content: center;
                        text-transform: uppercase;

                        button {
                            background: transparent;
                            border: 0;
                            color: #00171F;
                            line-height: 20px;

                            i {
                                color: #00171F;
                            }
                        }
                    }

                    .item-selected--related {
                        display: flex;
                        flex-direction: row;
                        background: rgba($primary, 0.5);
                        ;
                        padding: 3px 5px;
                        border-radius: 20px;
                        justify-content: center;
                        text-transform: uppercase;

                        button {
                            background: transparent;
                            border: 0;
                            color: #00171F;
                            line-height: 20px;

                            i {
                                color: #00171F;
                            }
                        }
                    }

                    .item-message--error {
                        color: $danger;
                        line-height: 15px;
                    }

                    .item-message--related {
                        color: $success;
                        line-height: 15px;
                    }
                }
            }
        }
    }
}

.date-time-picker-inline:after {
    content: '';
    clear: both;
    display: block;
}