.overlay-view-default{
  font-size: 13px;
}

.button-delete-map{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.select-copy-coverage{
  min-width: 300px;
}

.select-copy-map{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  flex-wrap: wrap;
  @media (max-width: 576px) {
    justify-content: flex-start;
  }
  
  .filter-bus-copy{
    width: auto;
    min-width: 300px;
  }

  .select-input{
    min-width: 300px;
    margin: 0 20px;
    @media (max-width: 576px) {
      margin: 0;
    }
  }
  .title-select{
    min-width: 150px;
  }
}