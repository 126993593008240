@import '/src/theme/assets/sass/components/variables.custom';

.create-package-offcanvas {
  &--rtl {
    right: -50vw;
    -webkit-animation: slide-right 0.5s forwards;
    animation: slide-right 0.5s forwards;
    @-webkit-keyframes slide-right {
      100% {
        right: 0;
      }
    }
    @keyframes slide-right {
      100% {
        right: 0;
      }
    }
  }
  @media screen and (max-width: 992px) {
    width: 100vw;
    height: 100vh;
    left: -50vw;
  }
  position: fixed;
  bottom: 0;
  background-color: #fbfbfb;
  z-index: 1055;
  width: 50vw;
  height: calc(100vh - 85px);
  left: -50vw;
  -webkit-animation: slide 0.5s forwards;
  animation: slide 0.5s forwards;
  .create-package-offcanvas-switcher {
    &--rtl {
      display: flex;
      flex-direction: row-reverse;
      justify-content: start;
      align-items: center;
      & > input {
        position: relative;
        width: 2.5rem !important;
        height: 1.5rem;
        font-weight: 400;
        margin-left: 0;
        &:not(:checked) {
          background-color: rgb(239, 242, 245);
          background-image: url('../../../../../../../../public/assets/images/icons/circle-not-check.svg') !important;
          border: none;
        }
        &:checked {
          background-image: url('../../../../../../../../public/assets/images/icons/circle-check.svg') !important;
        }
      }
      & > label {
        margin-left: 3.75rem;
        margin-right: 0;
        font-weight: 400;
      }
      &--active-auto-discount {
        & > label {
          text-transform: uppercase;
        }
      }
    }
    display: flex;
    flex-direction: row-reverse;
    justify-content: start;
    align-items: center;
    & > input {
      position: relative;
      width: 2.5rem !important;
      height: 1.5rem;
      margin-left: 0;
      font-weight: 400;
      &:not(:checked) {
        background-color: rgb(239, 242, 245);
        background-image: url('../../../../../../../../public/assets/images/icons/circle-not-check.svg') !important;
        border: none;
      }
      &:checked {
        background-image: url('../../../../../../../../public/assets/images/icons/circle-check.svg') !important;
      }
    }
    & > label {
      font-weight: bold;
    }
    &--active-auto-discount {
      & > label {
        text-transform: uppercase;
      }
    }
  }
  .create-package-offcanvas-switcher-label-left {
    &--rtl {
      & > label {
        padding-left: 1rem;
        font-weight: bold;
      }
    }
    & > label {
      padding-right: 1rem;
      font-weight: bold;
    }
  }
  &:not(.create-package-offcanvas--rtl) .input-group > .input-group-text {
    background-color: #eeeeee;
    border-top-left-radius: var(--bs-border-radius) !important;
    border-bottom-left-radius: var(--bs-border-radius) !important;
  }
  &:is(.create-package-offcanvas--rtl) .input-group > .input-group-text {
    background-color: #eeeeee;
    border-top-right-radius: var(--bs-border-radius) !important;
    border-bottom-right-radius: var(--bs-border-radius) !important;
  }
  .create-package-offcanvas-checkbox {
    &--rtl {
      display: flex;
      justify-content: start;
      align-items: center;
      height: 21px;
      padding-left: 0;
      & > input {
        border-radius: 3px;
        width: 18px;
        height: 18px;
        margin-left: 8px;
      }
      & > label {
        font-weight: 400 !important;
        font-size: 14px;
        line-height: 21px;
        color: #6e6b7b;
      }
    }
    display: flex;
    justify-content: start;
    align-items: center;
    height: 21px;
    & > input {
      border-radius: 3px;
      width: 18px;
      height: 18px;
      margin-right: 8px;
    }
    & > label {
      font-weight: 400 !important;
      font-size: 14px;
      line-height: 21px;
      color: #6e6b7b;
    }
  }
  &--header {
    height: 60px;
    padding: 13px 24px;
    background: $warning;
    color: $b8ak-font-color;
    &--title {
      height: 34px;
      line-height: 34px;
      font-weight: 400;
      margin: 0;
      font-size: 18px;
    }
    & > .btn-close {
      height: 34px;
      opacity: 1;
    }
  }
  &--body {
    height: calc(100vh - 85px - 60px);
    overflow: auto;
    padding: 28px;
    background-color: #fbfbfb;
    & > .accordion {
      .accordion-item {
        .accordion-header {
          .accordion-button {
            border-radius: 0 !important;
            background-color: #d9d9d9;
            font-size: 18px;
            font-weight: 500;
            color: $b8ak-font-color;
            padding: 1rem;
            height: 37px;
          }
          .accordion-button::after {
            background-image: url('../../../../../../../../public/assets/images/icons/down-head-arrow.svg');
          }
        }
        .accordion-collapse {
          & > .accordion-body {
            background-color: #ffffff;
          }
        }
      }
    }
  }
  &--package-info {
    .input-group {
      & > input {
        font-size: 14px;
      }
      & > select {
        padding: 0.25rem 1rem;
        font-size: 14px;
      }
      & > .form-label {
        font-size: 14px;
        font-weight: 500;
      }
      & > .input-group-text {
        min-height: 35px !important;
        min-width: 30px !important;
        width: 30px;
        background-color: #f5f8fa !important;
        padding: 1px 1px;
      }
    }
    .create-package-offcanvas-switcher-label-left {
      &--rtl {
        & > label {
          font-size: 17px;
          padding-left: 1rem;
          font-weight: 400 !important;
          text-transform: uppercase;
        }
      }
      & > label {
        font-size: 17px;
        padding-right: 1rem;
        font-weight: 400 !important;
        text-transform: uppercase;
      }
    }
  }
  &--custom-badge {
    font-size: 13px;
    font-weight: 400;
    background-color: #00171f;
    span {
      cursor: pointer;
    }
  }
  &--filter-by-city-input-group {
    &--rtl {
      position: relative;
      & > label {
        font-size: 14px;
      }
      & > .input-group-text {
        min-height: 35px !important;
        min-width: 30px !important;
        width: 30px;
        background-color: #f5f8fa !important;
        padding: 1px 1px;
        &::before {
          content: url('../../../../../../../../public/assets/images/icons/down-head-arrow.svg');
          height: calc(100% - 28px - 35px / 2);
          position: absolute;
          left: 15px;
          z-index: 1080;
          pointer-events: none;
        }
      }
    }
    position: relative;
    & > label {
      font-size: 14px;
    }
    & > .input-group-text {
      min-height: 35px !important;
      min-width: 30px !important;
      width: 30px;
      background-color: #f5f8fa !important;
      padding: 1px 1px;
      &::after {
        content: url('../../../../../../../../public/assets/images/icons/down-head-arrow.svg');
        height: calc(100% - 28px - 35px / 2);
        position: absolute;
        right: 15px;
        z-index: 1080;
        pointer-events: none;
      }
    }
  }
  &--selection-buses {
    &--title {
      font-size: 14px;
      font-weight: 700;
      color: $b8ak-font-color;
    }
    &--selection-area {
      border: 1px solid #eeeeee;
      border-radius: 6px;
    }
    &--checkbox {
      display: flex;
      justify-content: start;
      align-items: center;
      margin: 0;
      & > input {
        width: 18px;
        height: 18px;
      }
      & > .form-check-label {
        max-width: calc(100% - 1.75rem - 1rem);
        margin: 0 1rem;
        color: #6e6b7b;
        font-size: 14px;
        font-weight: 400 !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  &--price-management {
    .input-group {
      & > input {
        font-size: 14px;
      }
      & > .form-label {
        font-size: 14px;
        font-weight: 500;
      }
      & > .input-group-text {
        min-height: 35px !important;
        min-width: 30px !important;
        width: 30px;
        background-color: #f5f8fa !important;
        padding: 1px 1px;
      }
    }
    &--note {
      font-size: 13px;
      color: #bac0c2;
    }
    &--general-pricing-title {
      padding: 0;
      font-size: 14px;
      font-weight: 500;
    }
    &--custom-pricing-title {
      font-size: 14px;
      font-weight: 500;
    }
    &--bus-name {
      font-weight: 400;
      color: #a1a5b7;
      border-bottom: 1px solid #ececec;
      line-height: 0.1em;
      margin: 10px 0 20px;
      & > span {
        background-color: #ffffff;
        padding-right: 10px;
      }
      // &:after{
      //   content: '';
      //   position: absolute;
      //   top: 45%;
      //   right: 0;
      //   background: #ECECEC;
      //   width: 70%;
      //   transform: translateY(-50%);
      // }
    }
  }
  &--auto-discount-config {
    .create-package-offcanvas-switcher-label-left {
      &--rtl {
        & > label {
          font-size: 17px;
          padding-left: 1rem;
          font-weight: 400 !important;
          text-transform: uppercase;
        }
      }
      & > label {
        font-size: 17px;
        padding-right: 1rem;
        font-weight: 400 !important;
        text-transform: uppercase;
      }
    }
    .input-group {
      & > input {
        font-size: 14px;
      }
      & > .form-label {
        font-size: 14px;
        font-weight: 500;
      }
      & > .input-group-text {
        min-height: 35px !important;
        min-width: 30px !important;
        width: 30px;
        background-color: #f5f8fa !important;
        padding: 1px 1px;
      }
    }
    &--text-input {
      border-radius: 6px !important;
    }
  }
  &--btn-clear-all-data {
    border: 2px solid $b8ak-font-color !important;
  }
  label.form-check-label {
    font-weight: bold;
  }
}

@-webkit-keyframes slide {
  100% {
    left: 0;
  }
}

@keyframes slide {
  100% {
    left: 0;
  }
}
