.market-modal {
    .ticket-section {
        .ticket-item {
            padding: 10px;
            margin: 10px;
            border: 1px dashed #e5e5e5;
            position: relative;
            border-radius: 5px;
            .ticket-item-header {
                position: absolute;
                z-index: 10;
                background-color: #ffe2e5;
                border-radius: 5px;
            }

            &:hover {
                background-color: #e5e5e565;
            }
        }
    }
}
