.customer-name-input {
    &.has-error {

        input,
        .overlay-customer-statistic {
            border-color: #e96161 !important;
        }
    }

    .customer-input {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: 100%;
        cursor: text;

        .input-group-text {
            min-height: 34.3px;
            min-width: 34.3px;
            justify-content: center;
            border-color: #eeeeee;

            &:not(:last-child) {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        .input-container {
            flex: 1;

            input {
                min-height: 34.3px;
                padding: 5px 5px;
                border: 1px solid #eeeeee;
                border-radius: 0.475rem;

                &::placeholder {
                    color: #a1a5b7;
                    opacity: 1;
                }

                &:focus-visible,
                &:focus,
                &:visited {
                    outline: unset;
                    border-color: #b5b5c3 !important;
                }
            }

            &:not(:first-child) {
                input {
                    margin-left: -1px;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }

            .overlay-customer-statistic {
                height: 34.3px;
                padding: 5px 5px;
                border: 1px solid #eeeeee;
                border-radius: 0.475rem;
                margin-left: -1px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        .btn-popover {
            height: fit-content;
            background-color: rgba(0, 207, 232, 0.12);
            border: 0;
            border-radius: 17px;
            color: #00CFE8;
            padding: 1px 9px;
            margin-left: 9px;
        }
    }
}