.btn-submitted {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-success .modal-content {
  max-width: 500px;
  margin: auto;
}

.error-text-mobile {
  visibility: hidden;
  opacity: 0;
  padding: 10px;
  .text-danger {
    text-align: center;
  }
  @media (max-width: 786px) {
    visibility: visible;
    opacity: 1;
  }
}

.error-text-desktop {
  visibility: visible;
  opacity: 1;
  @media (max-width: 786px) {
    visibility: hidden;
    opacity: 0;
  }
}

.warning-limit-timeslot {
  margin-top: 10px;
  text-align: center;
}

.modal-reschedule {
  max-width: 900px;
  .reschedule-limit--status {
    font-size: 17px;
    padding: 0px 30px;
  }
  .modal-body {
    padding: 0px;
    .modal-title-description {
      padding: 30px 30px 0px 30px;
    }
    .reschedule-content {
      overflow: auto;
      height: calc(100% - 60px);
      .accordion {
        background-color: #ffffff;
        padding: 0px 30px 30px 30px;
        .accordion-item {
          border: 0;
          background-color: #ffffff !important;
          box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);

          &:not(:first-child) {
            margin-top: 24px;
          }

          &:last-of-type {
            border-radius: 0;
          }

          .accordion-header {
            background: #d9d9d9;
            height: 37px;
            padding: 1px 0 0 14px;

            .accordion-button {
              &::after {
                color: #00171f !important;
                // background-image: var(--bs-accordion-btn-icon) !important;
                background-image: url('data:image/svg+xml,<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.4586 7.10016C11.2633 7.30121 10.999 7.41406 10.7236 7.41406C10.4482 7.41406 10.1839 7.30121 9.98857 7.10016L6.24564 3.27885L2.55483 7.10016C2.35949 7.30121 2.09524 7.41406 1.8198 7.41406C1.54436 7.41406 1.28011 7.30121 1.08477 7.10016C0.987045 6.99981 0.909482 6.88042 0.85655 6.74888C0.803619 6.61734 0.776367 6.47624 0.776367 6.33374C0.776367 6.19124 0.803619 6.05015 0.85655 5.9186C0.909482 5.78706 0.987045 5.66767 1.08477 5.56732L5.50539 0.990385C5.60232 0.889208 5.71763 0.808902 5.84468 0.754099C5.97173 0.699296 6.108 0.671081 6.24564 0.671081C6.38327 0.671081 6.51955 0.699296 6.6466 0.754099C6.77365 0.808902 6.88896 0.889208 6.98589 0.990385L11.4586 5.56732C11.5564 5.66767 11.6339 5.78706 11.6869 5.9186C11.7398 6.05015 11.767 6.19124 11.767 6.33374C11.767 6.47624 11.7398 6.61734 11.6869 6.74888C11.6339 6.88042 11.5564 6.99981 11.4586 7.10016Z" fill="black"/></svg>') !important;
                background-size: unset;
                transition: all 0.3s ease-in-out 0s;
                transform: rotate(0deg);
                width: 13px;
                height: 11px;
              }

              &.collapsed::after {
                transform: rotate(-180deg);
              }

              &:not(.collapsed) {
                box-shadow: none;
              }
            }

            > button {
              padding: 8px;
              background: transparent !important;
              color: #00171f;
              font-size: 18px;
              font-weight: 500;
            }
          }

          .accordion-collapse {
            display: unset !important;

            .accordion-body {
              > div {
                padding: 20px;
              }

              overflow: hidden;
              max-height: 0px;
              -webkit-transition: all 0.3s ease-in-out 0s;
              -moz-transition: all 0.3s ease-in-out 0s;
              -ms-transition: all 0.3s ease-in-out 0s;
              -o-transition: all 0.3s ease-in-out 0s;
              transition: all 0.3s ease-in-out 0s;
              padding: 0;
            }

            &.show {
              .accordion-body {
                overflow: unset !important;
                max-height: 10000px !important;
              }
            }
          }
        }

        .form-section {
          &.summary-section {
            .accordion-header {
              background: #e4c66c;
            }
          }

          .section-title {
            color: #00171f;
            font-size: 17px;
            margin-top: 0px;
          }

          .sub-title {
            color: #00171f;
            font-size: 14px;
            font-weight: 400;
            margin: 21px 0 8px 0;
          }
        }
      }
      .btn-back-rs {
        background-color: #e9eaeb;
        color: inherit !important;
      }
    }
  }
}
