@import "../../../../theme/assets/sass/components/variables.custom";

#employer-table-page {
  .card-header {
    .table-search {
      input {
        height: 45px;
        background-color: $b8ak-input-color;
      }

      button {
        height: 45px;
        width: 49px;
        padding: 10px;
        background-color: $b8ak-bg-light;

        .svg-icon svg [fill]:not(.permanent):not(g) {
          fill: $b8ak-dark;
        }
      }
    }
  }

  .filter-city {
    @media (max-width: 786px) {
      margin-top: 10px;
    }
    .input-group-text {
      min-height: 45px;
      max-height: 45px;
    }
    .dropdown-toggle {
      height: 45px;
    }
  }

  .rdt_Table {
    border: 1px solid $b8ak-border-color;
    border-radius: $b8ak-border-radius;

    .rdt_TableHead {
      .rdt_TableHeadRow {
        background-color: $b8ak-input-color;
      }
    }

    .rdt_TableCol {
      font-weight: bold;
      justify-content: center;

      &:nth-child(2) {
        min-width: 250px;
      }
      &:nth-child(4) {
        min-width: 150px;
      }
    }

    input[type="checkbox"] {
      width: 18px;
      height: 18px;
    }

    .rdt_TableCell {
      padding: 5px;
      &:nth-child(2) {
        min-width: 250px;
      }
      &:nth-child(4) {
        min-width: 150px;
      }
    }
  }

  .contact-cell {
    padding: 15px 10px 15px 0;
  }

  .name-cell {
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 0;
    .image {
      border-radius: 50%;
      overflow: hidden;
      width: 40px;
      height: 40px;
      img {
        width: 100%;
        object-fit: cover;
        height: 100%;
        border-radius: 50%;
      }
      .defaultAvatar {
        border-radius: 50%;
        padding: 10px;
        display: flex;
        justify-content: center;
        background: rgb(228, 228, 228);
        height: 100%;
        img {
          object-fit: contain;
          width: 20px;
        }
      }
    }
    .group-name {
      margin-left: 10px;
      margin-right: 10px;
      max-width: 150px;
    }
  }

  .action-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px;
      border-radius: 6px;
      background-color: #f6f6f6;
    }

    div:first-child {
      overflow: visible !important;
    }
  }

  .page-size {
    border-radius: 5px;
    border: 1px solid $b8ak-bg-light;
    background-color: $b8ak-input-color;
    font-size: 14px;
    height: 40px;
    padding: 10px;
    width: 70px;
  }

  .pagination {
    .nav-item {
      border-radius: 5px;
      margin-left: 10px;
      margin-right: 10px;
      display: flex;
      width: 40px;
      align-items: center;
      justify-content: center;
      border: 1px solid $b8ak-border-color;
      background-color: $b8ak-input-color;
    }

    .page-item .page-link {
      font-size: 14px;
      height: 40px;
    }

    .break-item {
      display: flex;
      align-items: center;
    }
  }

  .table-header {
    margin: auto;
    border-start-start-radius: 5px;
    border-start-end-radius: 5px;
    border: 1px solid $b8ak-bg-light;
    padding: 10px 15px;
    font-size: $b8ak-root-font-size;
    font-weight: $b8ak-input-font-weight;

    .form-select {
      font-size: $b8ak-root-font-size;
      border: 0;
    }

    .check-status {
      flex: 1;
      font-size: $b8ak-root-font-size;

      .form-check-input {
        width: 23px;
        height: 23px;
      }
    }
  }

  .employer-body {
    position: relative;

    .employer-loading {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 10;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      font-size: 20px;
      border-radius: 5px;
      background-color: transparent;
    }
  }
}

.text-bold {
  font-weight: bold;
}

.text {
  &--max-content {
    width: max-content;
  }
}

.user-active {
  background-color: $b8ak-user-active;
  color: #ffffff;
}

.user-deactive {
  background-color: $b8ak-user-deactive;
  color: #ffffff;
}
.user-admin {
  background-color: $b8ak-user-admin;
  color: $b8ak-user-admin-text;
}

.user-tech {
  background-color: $b8ak-user-tech;
  color: $b8ak-user-tech-text;
}

.user-driver {
  background-color: $b8ak-user-driver;
  color: $b8ak-user-driver-text;
}

.user-worker {
  background-color: $b8ak-user-worker;
  color: $b8ak-user-worker-text;
}
.btn-create-emp {
  margin-right: 10px;
}

.row-action {
  padding: 10px 0;
  img {
    padding: 0 10px;
  }
}

button.dropdown-toggle {
  &.filter-en {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  &.filter-ar {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.dropdown-body {
  padding: 0px 15px;
  min-width: 150px;

  .button-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.filter-action {
  button.dropdown-toggle {
    border-radius: 10px;
    padding: 10px 13px !important;
    i {
      display: contents;
    }
  }
}
.dropdown-toggle::after {
  display: none;
}

.icon-filter-dropdown {
  margin-left: 5px;
}

.row-action {
  padding: 10px 0;
  cursor: pointer;
  &:hover {
    background-color: #f6f6f6;
  }
  img {
    padding: 0 10px;
  }
}

.row-deactive {
  padding: 10px 0;
  img {
    padding: 0 10px;
  }
}

.table-users > div {
  overflow: visible !important;
  @media (max-width: 768px) {
    overflow-x: visible !important;
    overflow-y: auto !important;
  }
}

.status-label {
  min-width: 90px;
  text-align: center;
}
.role-label {
  min-width: 70px;
  text-align: center;
}
.switch {
  position: relative;
  display: inline-block;
  width: 41px;
  height: 22px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.QIsbi div:first-child {
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: visible !important;
}
.service-tree {
    width: 100% !important;
}
.shorten-text {
  display: block;
  line-height: 16px;
  max-height: 74px;
  -webkit-line-clamp: 1;
  visibility: visible;
  text-overflow: ellipsis;
  overflow:hidden;
  max-width: 200px;
}
.fa-copy:active {
  color: black;
}
.text-blue {
  color: #2196f3;
}
.pt-0 {
  padding-top: 0px !important;
}
.field-links {
  cursor: pointer;
}
.m-auto {
  margin: auto;
}