#wrap {
    margin-bottom: 0px;
    text-align: center;
    color: #333;
}
.wrapper-error-domain {
    *zoom: 1;
    width: 90%;
    padding-left: 10px;
    padding-right: 10px;
    padding-right: 0.625rem;
    margin-left: auto;
    margin-right: auto;
    *zoom: 1;
    overflow: visible;
    position: relative;
    text-align: left;
}
.no-helpdesk {
    background-color: #ffffff;
    border: 1px solid #c9c9c9;
    margin: 50px auto;
    padding: 18px 20px 30px 20px;
    text-align: center;
    max-width: 700px;
    border-radius: 10px;
}
.no-helpdesk .p {
    font-size: 20px;
    line-height: 1.5em;
    margin: 0px;
    text-align: center;
}
.p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}
.h2 {
    font-size: 2em;
    font-weight: bold;
    line-height: 2em;
}

.h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}
.a {
    color: #336699;
}