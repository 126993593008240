@import "../../../../../theme/assets/sass/components/variables.custom";

* {
  padding: 0;
  margin: 0;
  scrollbar-color: #616161;
  ::-webkit-scrollbar,
  *::-webkit-scrollbar {
      background-color: transparent;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 0.5rem;
  }

  ::-webkit-scrollbar-thumb,
  *::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: var(--bs-gray-500);
      min-height: 24px;
      transition: all 1s linear;
  }

  ::-webkit-scrollbar-thumb:focus,
  *::-webkit-scrollbar-thumb:focus {
      background-color: var(--bs-gray-600);
      width: 0.6rem;
      height: 0.6rem;
  }

  ::-webkit-scrollbar-thumb:active,
  *::-webkit-scrollbar-thumb:active {
      background-color: var(--bs-gray-600);
      width: 0.6rem;
      height: 0.6rem;
  }

  ::-webkit-scrollbar-thumb:hover,
  *::-webkit-scrollbar-thumb:hover {
      background-color: var(--bs-gray-600);
      width: 0.6rem;
      height: 0.6rem;
  }

  ::-webkit-scrollbar-corner,
  *::-webkit-scrollbar-corner {
      background-color: var(--bs-gray-600);
  }
}

.form-multi-select {
  .dropdown-heading {
    justify-content: space-between;
  }

  .dropdown-heading-value {
    max-width: 600px;
  }
}

.btn-service {
  border-radius: 30px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 10px;
}

.btn-action-right {
  margin: 0 10px;
}

#package-details {
  &.--loading {
    .form-loading {
      display: flex !important;
    }
  }
  // Ticket status
  .w-15 {
    width: 15%;
  }
  .w-85 {
    width: 85%;
  }
  .badge {
    &.completed-rated {
      background-color: rgba($b8ak-order-completed-rated, 0.2);
      color: $b8ak-order-completed-rated;
    }

    &.completed {
      background-color: rgba($b8ak-order-completed-rated, 0.2);
      color: $b8ak-order-completed-rated;
    }

    &.disputed-customer {
      background-color: rgba($b8ak-order-disputed-customer, 0.2);
      color: $b8ak-order-disputed-customer;
    }

    &.disputed-sp {
      background-color: rgba($b8ak-order-disputed-other, 0.2);
      color: $b8ak-order-disputed-other;
    }

    &.in-progress {
      background-color: rgba($b8ak-order-in-progress, 0.2);
      color: $b8ak-order-in-progress;
    }

    &.to-be-assign {
      background-color: rgba($b8ak-order-disputed-other, 0.2);
      color: $b8ak-order-disputed-other;
    }

    &.canceled {
      background-color: rgba($b8ak-order-canceled, 0.2);
      color: $b8ak-order-canceled;
    }
  }

  .sub-title {
    color: #ffa247;
  }

  .checkbox-item {
    color: #d8d6de;
    font-size: 12px;
    font-weight: 400;
    position: relative;

    input {
      -moz-appearance: none;
      -webkit-appearance: none;
      -o-appearance: none;
    }

    input[type="checkbox"]:after {
      content: "";
      display: block;
      height: 14px;
      width: 14px;
      border: 1px solid #d8d6de;
      border-radius: 3px;
    }

    input[type="checkbox"]:checked:after {
      content: url("../../../../../../public/assets/images/icons/checked.svg");
      background: #ffc20f !important;
      border: 0;
    }
  }

  .list-group-item {
    display: -webkit-inline-box;
  }

  .backup-number {
    .input-group-text {
      min-height: 0px !important;
    }
    input {
      padding: 0.5rem 0.75rem;
    }
  }

  .worker-name {
    width: 100%;
    display: -webkit-inline-box;
  }

  .w-100-40px {
    width: calc(100% - 40px);
  }
}

#package-timeline {
  .event-ticket-submitted {
    color: $b8ak-event-ticket-submitted;
  }
  .event-ticket-delivered {
    color: $b8ak-event-ticket-delivered;
  }
  .event-ticket-undelivered {
    color: $b8ak-event-ticket-undelivered;
  }
  .event-ticket-undo-delivery{
    color: $b8ak-event-ticket-delivered;
  }
  .event-ticket-reassigned {
    color: $b8ak-event-ticket-reassigned;
  }
  .event-ticket-disputed {
    color: $b8ak-event-ticket-disputed;
  }
  .event-ticket-completed {
    color: $b8ak-event-ticket-completed;
  }
  .event-ticket-confirmed {
    color: $b8ak-event-ticket-confirmed;
  }
  .event-ticket-declined {
    color: $b8ak-event-ticket-declined;
  }
  .event-ticket-canceled {
    color: $b8ak-event-ticket-canceled;
  }
  .event-ticket-canceled-refund {
    color: $b8ak-event-ticket-canceled-refund;
  }
}
.timeline-label {
  position: relative;

  $label-width: 50px;

  &:before {
    content: "";
    position: absolute;
    left: $label-width + 19px !important;
    width: 3px;
    top: 0;
    bottom: 0;
    background-color: #eff2f5;
  }

  //
  .timeline-item {
    display: flex;
    align-items: flex-start;
    position: relative;
    margin-bottom: 1.7rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .timeline-label {
    width: $label-width;
    flex-shrink: 0;
    position: relative;
    color: #3f4254;
  }

  .timeline-badge {
    flex-shrink: 0;
    background: #ffffff;
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: relative;
    margin-top: 1px;
    margin-left: 0.9rem !important;
    padding: 3px !important;
    border: 6px solid #ffffff !important;

    span {
      display: block;
      border-radius: 100%;
      width: 6px;
      height: 6px;
      background-color: #eff2f5;
    }
  }

  .timeline-content {
    flex-grow: 1;
  }
}
.timeline-label-ar {
  position: relative;

  $label-width: 50px;

  &:before {
    content: "";
    position: absolute;
    right: $label-width + 19px !important;
    width: 3px;
    top: 0;
    bottom: 0;
    background-color: #eff2f5;
  }

  //
  .timeline-item {
    display: flex;
    align-items: flex-start;
    position: relative;
    margin-bottom: 1.7rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .timeline-label {
    width: $label-width;
    flex-shrink: 0;
    position: relative;
    color: #3f4254;
  }

  .timeline-badge {
    flex-shrink: 0;
    background: #ffffff;
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: relative;
    margin-top: 1px;
    margin-right: 0.2rem !important;
    padding: 3px !important;
    border: 6px solid #ffffff !important;

    span {
      display: block;
      border-radius: 100%;
      width: 6px;
      height: 6px;
      background-color: #eff2f5;
    }
  }

  .timeline-content {
    flex-grow: 1;
  }
}
.b8ak-order {
  background: #d7f0f3;
  color: #00cfe8;
}
.customer-tags {
  background: #00171f;
  color: #ffffff;
}
.h-200 {
  height: 200px;
}
.grow {
  flex-grow: 1;
}

.form-loading {
  position: absolute;
  display: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(153, 153, 153, 0.5);
  justify-content: center;
  align-items: center;
}

#other-notes--section {
  .note-title {
    font-size: 17px;
  }
  .note-subtitle {
    font-size: 14px;
  }
  .note-content {
    textarea {
      font-size: 15px;
    }
  }
}

.attendance-and-delivery-actions-popover {
  .popover-arrow {
    display: none;
  }
  .attendance-and-delivery-actions-container {
    width: fit-content;
    border-radius: 6px;
    font-size: 14px;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
    transform: translateX(-1rem);
    *{
      font-family: "Tajawal", sans-serif;
    }
    .btn{
      height: 2rem;
      font-size: 14px;
      font-weight: 500 !important;
      & > p{
        line-height: 2rem;
      }
    }
    .btn.btn-outline-light {
      color: $b8ak-font-color;
    }
    .action{
      &--success:hover{
        color: #39D5AD !important;
      }
      &--danger:hover{
        color: #E96161 !important;
      }
      &--warning:hover{
        color: #FFA247 !important;
      }
      &--selected{
        &--success{
          color: #39D5AD !important;
        }
        &--danger{
          color: #E96161 !important;
        }
        &--warning{
          color: #FFA247 !important;
        }
        &--success,&--danger,&--warning{
        }
        &--success:disabled,&--danger:disabled,&--warning:disabled{
          opacity: 1;
          &:hover{
            background-color: var(--bs-btn-hover-bg) !important;
          }
        }
      }
    }
  }
}

.modal-undo-delivery-action {
  .modal-dialog {
    max-width: 600px;
  }
}

.icon-paid {
  width: 20%;
  height: 37px;
  background: #F6F6F6;
  border: 1px solid #E6E5EA;
  border-radius: 5px 0px 0px 5px;
  text-align: center;
  svg {
    margin-top: 9px;
    margin-left: 5px;
  }
  &.rtl {
    border-radius: 0px 5px 5px 0px !important;
    svg {
      margin-right: 5px;
    }
  }
}
.select-paid {
  border-radius: 0px 5px 5px 0px;
  height: 37px;
  padding: 0rem 3rem 0rem 1rem;
  &.rtl {
    border-radius: 5px 0px 0px 5px !important;
    padding: 0rem 1rem 0rem 3rem !important;
  }
}
