.box-day-select{
  margin: 20px 0;

  .title {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .title-day{
      min-width: 100px;
    }
  }
}

.box-action-time{
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    justify-content: flex-start;
  }
  .action-time{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #a1a5b7;
    border-radius: 6px;
    padding: 10px;
    cursor: pointer;
  }
}

.disable-none-select-day{
  pointer-events: none;
  opacity: 0.3;
}

.icon-delete-hour {
  font-size: 25px !important;
  color: red;
}
.icon-add-hour {
  font-size: 25px !important;
  color: #47b3a8;
}
.icon-copy-hour {
  font-size: 25px !important;
  color: black;
}
