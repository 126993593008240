@import 'theme/assets/sass/components/variables.custom';

.package-time-table {
  display: flex;
  flex-direction: column;
  border: 1px solid #eeeeee;
  border-bottom: none;
  padding: 0px;

  .package-table-header {
    display: flex;
    margin-right: 0px;
    margin-left: 0px;

    .date-title {
      background: rgba(238, 238, 238, 0.52);
      line-height: 40px;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
    }

    .date-title.isRTL {
      border-left: 1px solid #eeeeee;
    }

    .date-title:not(.isRTL) {
      border-right: 1px solid #eeeeee;
    }

    .time-title {
      background: rgba(238, 238, 238, 0.52);
      line-height: 40px;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .package-table-body {
    position: relative;
    height: 200px;
    overflow-y: overlay;

    .package-table-row {
      border-bottom: 1px solid #eeeeee;
      margin-right: 0px;
      margin-left: 0px;

      .selected-item--wrapper.isRTL {
        border-left: 1px solid #eeeeee;
      }

      .selected-item--wrapper:not(.isRTL) {
        border-right: 1px solid #eeeeee;
      }

      .selected-item {
        border-top: 0;
        line-height: 30px;

        .date-item {
          padding: 5px;
          font-size: 12px;

          .item-wrapper {
            display: flex;
            justify-content: center;
            flex-direction: row;
            background: $primary;
            border-radius: 20px;
            box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
          }

          .item-wrapper--related {
            display: flex;
            justify-content: center;
            flex-direction: row;
            background: rgba($primary, 0.5);
            border-radius: 20px;
          }

          .item-selected {
            display: flex;
            justify-content: center;
            text-transform: uppercase;
            flex-grow: 5;

            span {
              padding: 0px 4px;
            }
          }

          .button-wrapper {
            display: flex;
            width: 10px;

            button {
              background: transparent;
              border: 0;
              color: #00171f;
              line-height: 20px;
              padding: 1px 4px;

              i {
                color: #00171f;
                font-size: 12px;
              }
            }
          }

          .item-message--error {
            color: $danger;
            line-height: 15px;
          }

          .item-message--related {
            color: $success;
            line-height: 15px;
          }
        }
      }

      .time-slot-list {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: auto;
        display: flex;

        .evening-shift--time-slot,
        .morning-shift--time-slot {
          display: block;
          width: 50%;

          .slot-item--wrapper {
            padding: 5px;
          }

          .time-slot-item {
            width: 100%;
            background: transparent;
            font-size: 12px;
            border: none;
            padding: 6px;
            text-align: center;
            border-top-width: 0;
            display: flex;
            flex-direction: row;
            border-radius: 20px;
            box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
            justify-content: center;
            color: #00171f;

            .shift-icon {
              padding: 2px;
              display: flex;
              align-self: center;

              svg {
                width: 12px;
                height: 12px;
              }
            }

            .time-slot-content {
              margin: 0;
              text-transform: uppercase;
              justify-content: center;
              align-items: center;
              padding: 0px 4px;
              display: flex;
            }

            &.disabled {
              background: #f8f8f8;
              color: #bdbdbd;
              position: relative;

              .shift-icon {
                svg {
                  width: 12px;
                  height: 12px;

                  path {
                    fill: rgba(170, 170, 170, 1);
                  }
                }
              }
            }

            &.active {
              background: $primary;
            }
          }

          .time-slot-item:not(.disabled):hover {
            background: rgba($primary, 0.5);
            transition: all 0.75s ease;
          }
        }

        .disabled-line {
          position: absolute;
          width: 100%;
          border-radius: 20px;
          overflow: hidden;
          top: 0px;
          bottom: 0px;
          left: 0px;
          right: 0px;

          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
