@import '../../../../../theme/assets/sass/components/variables.custom';

.loyalty-page {
    .edit-loyalty-equation-btn {
        width: 195px;
        height: 38px;
        line-height: 38px;
        float: right;
        font-weight: 700;
    }

    .line {
        width: 60px;
        height: 0px;
    
        border: 1px solid #EEEEEE;
        transform: rotate(90deg);
    }
    
    .first-page {
        background: #FFC20F;
        border-radius: 6px;
    }

    .mt-15px {
        margin-top: 15px !important;
    }

    .loyalty-table-title {
        box-sizing: border-box;
        height: 84px;
        left: 28px;
        top: 85px;
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        border-radius: 4px;
    
        .loyalty-information-title {
            font-size: 13px;
            color: #BAC0C2;
        }
    
        .loyalty-information-content {
            font-size: 16px;
        }
    
        div {
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            position: relative;
        }
    
        span.line {
            position: absolute;
            margin-left: 100%;
        }
    }
    
    .loyalty-table-header {
        height: 33.61px !important;
        line-height: 40px !important;
        font-weight: 600 !important;
        background-color: #eeeeee;
        color: #464e5f;
        text-align: center;
        border-radius: 4px 4px 0 0;
    }
    
    .loyalty-table {
        position: relative;
        padding: 0;
        * {
            font-family: 'Tajawal', sans-serif !important;
        }
    
        &>div {
            width: 100%;
            padding: 0;
    
            &:first-child {
                border-bottom-left-radius: 6px;
                border-bottom-right-radius: 6px;
                border: 1px solid #eeeeee;
                border-top: none;
                height: 530px;
    
                .rdt_Table {
                    width: 100%;
                    min-width: 800px;
    
                    &>.rdt_TableHead {
                        height: 50px;
    
                        &>.rdt_TableHeadRow {
                            height: 50px;
                            min-height: 0;
    
                            &>.rdt_TableCol {
                                background-color: var(--bs-primary);
                                color: var(--bs-gray-900);
                                justify-content: start;
    
                                &:nth-child(1) {
                                    justify-content: center;
                                }
    
                                &:nth-child(2) {
                                    justify-content: center;
                                }
    
                                &:nth-child(3) {
                                    justify-content: center;
                                }
                            }
                        }
                    }
    
                    &>.rdt_TableBody {
                        overflow: overlay;
                        height: 480px;
                        display: block;
    
                        &>.rdt_TableRow {
                            height: 80px;
    
                            &>.rdt_TableCell {
                                padding: 0;
                                height: 80px;
                                padding: 10px 0;
    
                                &>p {
                                    color: var(--bs-gray-900);
                                    font-size: 14px;
                                    font-weight: 500;
                                    margin: 0;
                                    width: 100%;
                                    position: absolute;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }  
                            }
                        }
                        &>.rdt_TableRow:last-of-type {
                            border-bottom-style: solid;
                            border-bottom-width: 1px;
                            border-bottom-color: rgba(0,0,0,.12);
                        }
                    }
                }
            }
    
            &:last-child>div {
                border: none !important;
                justify-content: end !important;
                .page-item.active {
                    .page-link{
                        color: #00171F;
                    }
                }
            }
        }
    }
}

.loyalty-page-rtl {
    .edit-loyalty-equation-btn {
        float: left;
    }
    .loyalty-table-title {
        span.line {
            position: absolute;
            margin-left: unset;
            margin-right: 100%;
        }
    }
}