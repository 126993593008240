.service-management-container {
  .service-management-container-bg {
    background-color: var(--bs-body-bg);
    height: 100vh;
  }

  * > .form-switch {
    & > input {
      width: 2.5rem;
      height: 1.5rem;
      &:not(:checked) {
        background-color: rgb(239, 242, 245);
        background-image: url('../../../../public/assets/images/icons/circle-not-check.svg') !important;
        border: none;
      }
      &:checked {
        background-image: url('../../../../public/assets/images/icons/circle-check.svg') !important;
      }
    }
  }
}
