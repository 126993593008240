.list-item--select{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  max-height: 200px;
  overflow-y: scroll;
  flex-wrap: wrap;
  .item{
    padding: 10px 20px;
    background: #00171F;
    color: #FFFFFF;
    border-radius: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
    @media (max-width: 786px) {
      margin-right: 3px;
    }
    .name{
      padding: 0 15px;
    }
    .close{
      cursor: pointer;
    }
  }
}
.box-step-2{
  width: 100%;
}
  
.dropdown-content {
  input:focus {
    background: #f1f3f5;
  }
  input:hover {
    background: #e2e6ea;
  }
}
.select-service .dropdown-content ul .toggle.collapsed {
  writing-mode: vertical-lr;
  -webkit-transform: rotate(267deg);
}