.form-select-time{
  border-radius: 10px !important;
}

.select-transport{
  display: flex;
  justify-content: space-between;
  align-items: center;

  span{
    display: inline-block;
    margin: 0 10px;
  }
}

.title-transport{
  font-size: 18px;
  margin: 20px 10px;
}

.nav-tabs .nav-link{
  color: #5e6278 !important;

  &.active{
    color: #ffc20f !important;
  }
}

.transport-time-group{
  display: flex;
  @media (max-width: 576px) {
    flex-wrap: wrap;
  }
}