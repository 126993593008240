@import '../../../../../theme/assets/sass/components/variables.custom';

.service-management-switcher {
  display: flex;
  flex-direction: row-reverse;
  justify-content: start;
  align-items: center;
  & > input {
    position: relative;
    font-weight: 400;
    margin-left: 0;
    width: 2.5rem !important;
    height: 1.5rem;
    &:not(:checked) {
      background-color: rgb(239, 242, 245);
      background-image: url('../../../../../../public/assets/images/icons/circle-not-check.svg') !important;
      border: none;
    }
    &:checked {
      background-image: url('../../../../../../public/assets/images/icons/circle-check.svg') !important;
    }
  }
  & > label {
    margin-right: 3.75rem;
    font-size: 17px;
  }
}
.service-management-switcher-label-left {
  & > label {
    padding-right: 1rem;
    font-weight: 400;
    text-transform: uppercase;
  }
}

.service-management-table {
  border: 1px solid #eeeeee;
  &--header {
    & > div {
      display: flex;
      align-items: center;
      font-weight: bold;
      height: 50px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    & > div:not(&--bg-sources-list) {
      background-color: var(--bs-primary);
      color: var(--bs-gray-900);
      justify-content: start;
      &:last-child {
        justify-content: center;
      }
    }
    &--bg-sources-list {
      background-color: #eeeeee;
      color: #464e5f;
      justify-content: center;
    }
  }
  &--body {
    & > div > .row > div {
      display: flex;
      justify-content: start;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:first-child {
        justify-content: center;
      }
      &:nth-child(2) {
        display: block;
        line-height: 60px;
      }
      &:nth-child(3) {
        display: block;
        line-height: 60px;
      }
      &:last-child {
        justify-content: center;
      }
    }
  }
}

.service-list-container {
  background-color: var(--bs-body-bg);
  height: calc(100vh - 2rem - (2.25rem + 50px) - 26px - 1rem);
  border: 1px solid #f6f6f6;
  border-radius: 6px;
  overflow: overlay;
  .service-item {
    position: relative;
    transition: linear 0.2s;
    padding: 15px 20px;
    border-radius: 6px;
    border: 1px solid #f6f6f6;
    background: #ffffff;
    cursor: pointer;
    &--selected {
      border-left: 6px solid #ffc20f;
      background-color: #ffefc3;
      &--rtl {
        border-left: none !important;
        border-right: 6px solid #ffc20f !important;
      }
    }

    &--name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 600;
      color: #5d4600;
    }
    &--sub-title {
      margin-top: 10px;
      font-weight: 400;
      color: #71787b;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &* {
        color: #71787b;
      }
    }
    &--btn-wrapper {
      & > .service-management-package-switcher {
        display: flex;
        justify-content: center;
        align-items: center;
        & > input {
          margin-top: 1px;
          cursor: pointer;
        }
      }
      display: flex;
      align-items: center;
      position: absolute;
      right: 8px;
      bottom: 8px;
      &--rtl {
        display: flex;
        align-items: center;
        position: absolute;
        right: unset !important;
        left: 8px !important;
        bottom: 8px;
        & > .service-management-package-switcher {
          display: flex;
          justify-content: center;
          align-items: center;
          & > input {
            margin-top: 1px;
          }
        }
      }
      &--selected {
        & > .service-item--btn-edit {
          background-color: transparent;
          border-color: #ffc20f;
        }
      }
    }
    &--btn-edit {
      transition: linear 0.2s;
      cursor: pointer;
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f6f6f6;
      border: 1px solid;
      border-color: #eeeeee;
      border-radius: 6px;
      &:hover {
        border-color: #ffc20f;
        background-color: #ffefc3;
      }
      & > svg > path {
        fill: #a1a5b7;
      }
    }
  }
}

.modal-add-service,
.modal-edit-service {
  .input-group {
    & > input {
      font-size: 14px;
    }
    & > .form-label {
      font-size: 14px;
      font-weight: 500;
    }
    & > .input-group-text {
      border-top-left-radius: var(--bs-border-radius) !important;
      border-bottom-left-radius: var(--bs-border-radius) !important;
    }
  }
  .modal-header {
    background-color: #ffc20f;
  }
  .modal-footer {
    background-color: #f6f6f6;
  }
  &--rtl {
    .input-group {
      & > input {
        font-size: 14px;
      }
      & > .form-label {
        font-size: 14px;
        font-weight: 500;
      }
      & > .input-group-text {
        border-top-right-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important;
      }
    }
    .modal-header {
      background-color: #ffc20f;
    }
    .modal-footer {
      background-color: #f6f6f6;
    }
    .service-management-switcher {
      display: flex;
      flex-direction: row-reverse;
      justify-content: start;
      align-items: center;
      & > input {
        position: relative;
        font-weight: 400;
        margin-left: 0;
        width: 2.5rem !important;
        height: 1.5rem;
        &:not(:checked) {
          background-color: rgb(239, 242, 245);
          background-image: url('../../../../../../public/assets/images/icons/circle-not-check.svg') !important;
          border: none;
        }
        &:checked {
          background-image: url('../../../../../../public/assets/images/icons/circle-check.svg') !important;
        }
      }
      & > label {
        margin-left: 3.75rem;
        margin-right: 0;
        font-weight: 400;
      }
    }
    .service-management-switcher-label-left {
      & > label {
        padding-left: 1rem;
        font-weight: 400;
      }
    }
  }
}
.modal-content {
  .modal-header {
    .modal-title {
      font-size: 18px;
    }
  }
}
