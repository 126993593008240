@import "../../../../../theme/assets/sass/components/variables.custom";

$text-muted: #7d8699 !default;
.form-multi-select {
    .dropdown-heading {
        justify-content: space-between;
    }

    .dropdown-heading-value {
        max-width: 600px;
    }
}

.btn-service {
    border-radius: 30px;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 10px;
}
.shorten-text {
    display: block;
    line-height: 16px;
    max-height: 74px;
    -webkit-line-clamp: 1;
    visibility: visible;
    text-overflow: ellipsis;
    overflow:hidden;
    max-width: 240px;
}
.badge {
    &.completed-rated {
        background-color: rgba($b8ak-order-completed-rated, 0.2);
        color: $b8ak-order-completed-rated;
    }

    &.completed {
        background-color: rgba($b8ak-order-completed-rated, 0.2);
        color: $b8ak-order-completed-rated;
    }

    &.disputed-customer {
        background-color: rgba($b8ak-order-disputed-customer, 0.2);
        color: $b8ak-order-disputed-customer;
    }

    &.disputed-sp {
        background-color: rgba($b8ak-order-disputed-other, 0.2);
        color: $b8ak-order-disputed-other;
    }

    &.in-progress {
        background-color: rgba($b8ak-order-in-progress, 0.2);
        color: $b8ak-order-in-progress;
    }

    &.to-be-assign {
        background-color: rgba($b8ak-order-disputed-other, 0.2);
        color: $b8ak-order-disputed-other;
    }

    &.canceled {
        background-color: rgba($b8ak-order-canceled, 0.2);
        color: $b8ak-order-canceled;
    }
}
.bi-wrench-adjustable {
    color: #0FB0A0;
}

.h-400 {
    height: 400px;
}
.h-200 {
    height: 200px;
}
.h-615px {
    height: 615px;
}
.mh-605px {
    max-height: 560px;
}


.timeline-label {
    position: relative;
  
    $label-width: 50px;
  
    &:before {
      content: '';
      position: absolute;
      left: $label-width + 19px !important;
      width: 3px;
      top: 0;
      bottom: 0;
      background-color: #eff2f5;
    }
  
    //
    .timeline-item {
      display: flex;
      align-items: flex-start;
      position: relative;
      margin-bottom: 1.7rem;
  
      &:last-child {
        margin-bottom: 0;
      }
    }
  
    .timeline-label {
      width: $label-width;
      flex-shrink: 0;
      position: relative;
      color: #3f4254;
    }
  
    .timeline-badge {
      flex-shrink: 0;
      background: #ffffff;
      width: 1rem;
      height: 1rem;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      position: relative;
      margin-top: 1px;
      margin-left: 0.9rem !important;
      padding: 3px !important;
      border: 6px solid #ffffff !important;
  
      span {
        display: block;
        border-radius: 100%;
        width: 6px;
        height: 6px;
        background-color: #eff2f5;
      }
    }
  
    .timeline-content {
      flex-grow: 1;
    }
  }
  .timeline-label-ar {
    position: relative;
  
    $label-width: 50px;
  
    &:before {
      content: '';
      position: absolute;
      right: $label-width + 19px !important;
      width: 3px;
      top: 0;
      bottom: 0;
      background-color: #eff2f5;
    }
  
    //
    .timeline-item {
      display: flex;
      align-items: flex-start;
      position: relative;
      margin-bottom: 1.7rem;
  
      &:last-child {
        margin-bottom: 0;
      }
    }
  
    .timeline-label {
      width: $label-width;
      flex-shrink: 0;
      position: relative;
      color: #3f4254;
    }
  
    .timeline-badge {
      flex-shrink: 0;
      background: #ffffff;
      width: 1rem;
      height: 1rem;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      position: relative;
      margin-top: 1px;
      margin-right: 0.2rem !important;
      padding: 3px !important;
      border: 6px solid #ffffff !important;
  
      span {
        display: block;
        border-radius: 100%;
        width: 6px;
        height: 6px;
        background-color: #eff2f5;
      }
    }
  
    .timeline-content {
      flex-grow: 1;
    }
  }

  .w-200px {
    width: 200px;
  }
  .ml-3r {
    margin-left: 3rem;
  }
  .mh-200 {
    min-height: 200px;
  }
  .scroll::-webkit-scrollbar {
    width: 2px !important;
  }
  
  .attendance-delivery-action {
    .dropdown-menu {
      min-width: 100%;
      box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
      transform: translate(-2.5rem, -64px) !important;
      font-size: 14px !important;
    }
    .item-actions:hover, .item-actions:active, .item-actions:focus {
      background-color: unset !important;
      .item-content{
        background-color: #F6F6F6 !important;
      }
      &.attendance, &.delivery {
        color: #39D5AD !important;
        path {
          stroke:#39D5AD !important;
        }
      }
      &.unattendance, &.undelivery {
        color: #E96161 !important;
        path {
          stroke:#E96161 !important;
        }
      }
      &.undo-delivery {
        color: #FFA247 !important;
        path {
          fill: #FFA247 !important
        }
      }
    }
    .item-actions{
      padding: 0!important;
      margin: 0 !important;
      .item-content{
        padding: 5px 0;
      }
      &.disabled {
        color: #AEAEAE !important;
        &.attendance, &.delivery, &.unattendance, &.undelivery {
          path {
            stroke: #AEAEAE !important;
          }
        }
        &.undo-delivery {
          path {
            fill: #AEAEAE !important;
          }
        }
      }
      &.selected{
        cursor: auto;
        .item-content{
          // background-color: #F6F6F6 !important;
        }
        &.attendance, &.delivery {
          color: #39D5AD !important;
          path {
            stroke:#39D5AD !important;
          }
        }
        &.unattendance, &.undelivery {
          color: #E96161 !important;
          path {
            stroke:#E96161 !important;
          }
        }
      }
    }
  }
