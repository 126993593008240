.box-step-header{
  padding: 20px 25px;
}
.header-step{
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }


  .step-item{
    padding: 10px 25px;
    background: #F6F6F6;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    margin: 10px 0;
    span{
      font-size: 14px;
      color: #BAC0C2;
    }
  }

  .name-step{
    width: max-content;
  }

  .step-item.active{
    background-color: #00171F;
    span{color: #FFFFFF;}
  }

  .step-item.success{
    background-color: #FFC20F;
    span{color: #00171F;}
    padding: 10px 10px 10px 20px;
  }
}

.tick-icon{
  width: 15px;
  height: 15px;
  background: #00171F;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    width: 50%;
    }
}

.hidden-step{
  display: none !important;
}