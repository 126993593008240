.settings-container {
    .settings-container-bg {
        background-color: var(--bs-body-bg);
        min-height: 712px;
    }
}

.settings-container-side-tab-button {
    font-size: 14px;
    height: 52px;

    &--not-available::after {
        content: url("");
        display: block;
        line-height: 16px;
    }

    &--active {
        background-color: #FFC20F40;
    }

    &:hover {
        background-color: #FFC20F40;

        svg {
            color: var(--bs-body-color);
        }
    }
}