.selector-inline {
    /* position: relative; */

    .input-group-text:last-child {
        position: absolute;
        right: 0;
        z-index: 9;
        background: transparent;
        border: 0;
    }

    .selector-list {
        /* position: absolute;
        top:0;
        left:0;
        right:0;
        background: white;
        z-index: 9999; */
        display: flex;
        flex-direction: column;
        max-height: 350px;
        overflow: auto;
        border: 1px solid #f6f6f6;
        border-radius: 0 0 10px 10px;
        box-shadow: 6px 9px 18px -4px rgba(0, 0, 0, 0.29);
        -webkit-box-shadow: 6px 9px 18px -4px rgba(0, 0, 0, 0.29);
        -moz-box-shadow: 6px 9px 18px -4px rgba(0, 0, 0, 0.29);

        .selector-item {
            background: transparent;
            border: 0;
            padding: 15px 10px;
            text-align: left;

            &.selected {
                background: #ffc20f;
            }

            p {
                margin: 0 !important;
            }
        }
    }
}