.btn-action-right{
  margin: 0 10px;
}

.modal-success .modal-content{
  max-width: 500px;
  margin: auto;
}

.toggle-issues{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.cancel-box{
  padding: 20px;
}

.warning-refund{
  background: #e96161;
  color: #fff;
  padding: 20px;
  border-radius: 6px;
  text-align: center;
}

.checkbox-reason-group{
  .checkbox-reason{
    display: flex;
    align-items: center;
    input{
      max-width: 20px;
      max-height: 20px;
    }
    label{
      margin: 0 10px;
    }
  }
}

.toggle-remaining-left{
  margin-left: 100px;
  @media (max-width: 786px) {
    margin-left: 0;
  }
}

.toggle-remaining-right{
  margin-right: 100px;
  @media (max-width: 786px) {
    margin-right: 0;
  }
}