.btn-next{
  background: black !important;
  color: white !important;
  min-width: 50px !important;
}

.group-avatar{
  display: flex;
  justify-content: center;
  align-items: center;

  .text{
    .text-bold{
      font-weight: bold;
    }
  }
  .upload{
    position: relative;
    margin: 0 50px;
  }
  .upload .circle{
    background: #F5F6FA;
    cursor: pointer;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed grey;
    overflow: hidden;
    img{
      width: 35px;
    }
    .image-user{
      width: 100%;
    }
    .icon-action{
      position: absolute;
      background: #F3F6F9;
      border-radius: 6px;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: -10px;
      right: 10px;
    }
    .icon-edit{
      left: 10px;
      right: unset
    }
  }
}

.none{
  display: none;
}

.btn-delete-avatar{
  font-size: 13px;
}
.fa-edit{
  color: #333;
}
.fa-trash{
  color: #333;
}

.select-phone-type{
  border-color: #e9eaeb;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.text-grey{
  color: #BAC0C2;
}

.form-select.custom-select{
  color: #999;
  &:focus{
    color: black;
  }
  &.selected{
    color: black;
  }
}