.date-time-select {
    width: 100%;

    .react-datepicker {
        font-family: "Tajawal", sans-serif;
        width: 100%;
        font-size: 14px !important;
        border: 0 !important;
        border-radius: 0 !important;

        .react-datepicker__navigation-icon--next::before {
            border-color: black;
        }

        .react-datepicker__navigation-icon--previous::before {
            border-color: black;
        }

        .react-datepicker__navigation {
            top: 15px !important;
        }

        .react-datepicker__month-container {
            width: 100%;

            .react-datepicker__header {
                background-color: unset;
                border: 0;
                padding-top: 0;

                .react-datepicker__current-month {
                    font-weight: 400;
                    background-color: #eeeeee;
                    height: 50px;
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .react-datepicker__day-names {
            height: 50px;
            display: flex;
            justify-content: space-around;
            align-content: center;

            .react-datepicker__day-name {
                width: unset;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .react-datepicker__week {
            display: flex;
            justify-content: space-around;
            align-content: center;

            .react-datepicker__day {
                width: unset;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 50px;
                height: 50px;
                margin: 5px;

                &--selected {
                    border-radius: 50%;
                    background-color: #ffc20f;
                    color: black;
                    min-width: 50px;
                }

                &--keyboard-selected {
                    border-radius: 50%;
                    background-color: #ffffff;
                    color: black;
                }
            }
        }
    }

    .time-selection {
        max-height: 400px;
        overflow: scroll;

        .time-selection__item {
            &--disabled {
                background-color: #f8f8f8;
                color: #bdbdbd;
            }

            &.active {
                background-color: #ffc20f;
                color: black;
            }

            .form-check-input:checked[type=radio] {
                background-image: url("data:image/svg+xml,%3Csvg id='Forms_Checkbox_Resting' data-name='Forms / Checkbox / Resting' xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 18 18'%3E%3Crect id='Rectangle_3' data-name='Rectangle 3' width='18' height='18' rx='9' fill='%2300171f'/%3E%3Cpath id='Shape' d='M3.158,6.8.136,3.775a.465.465,0,0,1,0-.657L.794,2.46a.465.465,0,0,1,.657,0L3.486,4.5,7.846.136a.465.465,0,0,1,.657,0l.657.657a.465.465,0,0,1,0,.657L3.815,6.8a.465.465,0,0,1-.657,0Z' transform='translate(4.667 5.534)' fill='%23fff'/%3E%3C/svg%3E%0A") !important;
            }

            .form-check.form-check-solid .form-check-input {
                background-color: unset !important;
            }

            .form-check-input {
                height: 18px;
            }
        }
    }
}

.time-slot {
    margin: 30px;
}

.capacity-used {
    &.color-red {
        color: #e96161;
    }

    &.color-green {
        color: #5cb85c;
    }
}