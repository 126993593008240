.current {
  display: block;
}

.btn-next.btn-back {
  background-color: #E8E8E8 !important;
  color: #00171F !important;
}

.btn-next {
  background-color: #00171F !important;
  color: #FFFFFF !important;
}

.group-button-footer {
  justify-content: space-between;

  .btn-next {
    @media (max-width: 786px) {
      max-width: 150px !important;
    }
  }
}

.center-box {
  display: flex;
  justify-content: center;
}

.dropdown-heading button.clear-selected-button {
  display: none !important;
}

.dropdown-heading svg {
  color: black !important;
}

@media (max-width: 576px) {
  .modal-lg {
    max-width: 100% !important;
  }

  .modal-xl {
    max-width: 100% !important;
  }
}

.text-black {
  color: black !important;
}

.item-renderer {
  input {
    align-self: center;
  }
}

.select-service1 .tag-list .tag-item {
  display: inline-block;
  margin-left: 10px;
  color: black;
}

.dispatch-center-location.has-error input {
  border-color: #eeeeee;
}