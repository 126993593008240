.image-border{
  padding: 3px;
  border: 4px solid #FFC20F;
  border-radius: 6px;
  width: max-content;
}

.title-user-type{
  margin: 0 50px;
}

.role-select--item{
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 30px;
  padding: 15px;
  display: flex;
  align-items: center;
  border: 1px solid #BAC0C2;
  border-radius: 5px;
  border-style: dashed;
  cursor: pointer;

  @media (max-width: 786px) {
    margin-left: 0;
    margin-right: 0;
  }

  .image{
    margin: 0 20px;
  }

  .text{
    .name{
      font-size: 20px;
      font-weight: 400;
    }
    .desc{
      color: rgba(0, 23, 31, 0.69);
      font-weight: 400;
      font-size: 12px;
    }
  }

  &.active{
    background: rgba(255, 194, 15, 0.1);
  }
  &:hover{
    transition: .3s ease;
    background: rgba(255, 194, 15, 0.1);
  }
}

.box-step-1{
  width: 100%;
  max-width: 800px;
}

.disable-select{
  pointer-events: none;
  opacity: 0.5;
}