.tags-input-inline {
    position: relative;

    .tags-suggest {
        z-index: 999;
        background: #fff;
        left: 0;
        right: 0;
        position: absolute;
        max-height: 150px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        border: 1px solid #f6f6f6;
        border-radius: 0 0 10px 10px;
        box-shadow: 6px 9px 18px -4px rgba(0, 0, 0, 0.29);
        -webkit-box-shadow: 6px 9px 18px -4px rgba(0, 0, 0, 0.29);
        -moz-box-shadow: 6px 9px 18px -4px rgba(0, 0, 0, 0.29);

        button {
            background: transparent;
            border: 0;
            border-top: 1px solid #eee;
            padding: 8px 10px;
            text-align: left;
        }
    }

    .tags-selected {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 5px;

        .tags-selected__item {

            // &:not(:last-child) {
            //     margin-right: 10px;
            // }

            margin-top: 5px;
            display: flex;
            flex-direction: row;
            color: white;
            background-color: #00171F;
            border-radius: 20px;
            padding: 5px 8px;

            button {
                background: transparent;
                border: 0;

                i {
                    color: white;
                }
            }
        }
    }
    .invalid-feedback {
        display: flex;
        width: 100%;
        margin-top: 0.5rem;
        font-size: 0.925rem;
        color: #e96161;
    }
}