@import '../../../../../theme/assets/sass/components/variables.custom';

.service-management-package-switcher {
  & > input {
    position: relative;
    font-weight: 400;
    margin-left: 0;
  }
  & > label {
    margin-right: 3.75rem;
    font-weight: bold;
  }
}
.service-management-package-switcher-label-left {
  & > label {
    padding-right: 1rem;
    font-weight: bold;
  }
}

.service-management-package-table {
  position: relative;
  border-radius: 6px;
  height: fit-content;
  &--loading {
    height: calc(100vh - (3rem + 50px + 1.5rem + 100px + 35px + 3.75rem));
    overflow: hidden;
    * {
      padding: 0;
    }
  }
  &--header {
    &:first-child {
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
      border: 1px solid #eeeeee;
      border-bottom: none;
    }
    & > div {
      padding: 0;
      display: flex;
      align-items: center;
      color: var(--bs-gray-900);
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      height: 50px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    & > div:not(&--bg-package-list) {
      background-color: var(--bs-primary);
      color: var(--bs-gray-900);
      justify-content: start;
      &:nth-child(4) {
        justify-content: center;
      }
      &:nth-child(5) {
        justify-content: center;
      }
      &:nth-child(6) {
        justify-content: center;
      }
      &:nth-child(8) {
        justify-content: center;
      }
    }
    &--bg-package-list {
      background-color: #eeeeee;
      color: #464e5f;
      justify-content: center;
      font-size: 16px;
      font-weight: 600 !important;
      text-transform: none !important;
    }
  }
  &--body {
    position: relative;
    * {
      font-family: 'Tajawal', sans-serif !important;
    }
    & > div {
      width: 100%;
      padding: 0;
      &:first-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        border: 1px solid #eeeeee;
        border-top: none;
        height: calc(100vh - (3rem + 50px + 1.5rem + 100px + 35px));
        .rdt_Table {
          width: 100%;
          min-width: 800px;
          & > .rdt_TableHead {
            height: 50px;
            & > .rdt_TableHeadRow {
              height: 50px;
              min-height: 0;
              & > .rdt_TableCol {
                background-color: var(--bs-primary);
                color: var(--bs-gray-900);
                justify-content: start;
                &:nth-child(4) {
                  justify-content: center;
                }
                &:nth-child(5) {
                  justify-content: center;
                }
                &:nth-child(6) {
                  justify-content: center;
                }
                &:nth-child(8) {
                  justify-content: center;
                }
              }
            }
          }
          & > .rdt_TableBody {
            overflow: overlay;
            height: calc(100vh - (3rem + 50px + 1.5rem + 100px + 3.75rem + 35px));
            display: block;
            & > .rdt_TableRow {
              height: 80px;
              & > .rdt_TableCell {
                padding: 0;
                height: 80px;
                padding: 10px 0;
                position: relative;
                & > p {
                  color: var(--bs-gray-900);
                  font-size: 14px;
                  font-weight: 500;
                  margin: 0;
                  width: 100%;
                  position: absolute;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                &:nth-child(4) > p {
                  padding: 0rem 1rem;
                }
                &:nth-child(5) > p {
                  text-align: center;
                }
                &:nth-child(6) > p {
                  text-align: center;
                }
                &:nth-child(8) {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  & > button {
                    width: 39px;
                    height: 39px;
                    padding: 10px !important;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }
                  & > button > svg > path {
                    fill: #a1a5b7;
                  }
                }
              }
            }
          }
        }
      }
      &:last-child > div {
        border: none !important;
        justify-content: end !important;
      }
    }
  }
  .pagination {
    .page-link.active,
    .active > .page-link {
      color: #00171f;
    }
  }
}
