.section-selected {
    &.rtl {
        .sub-selected {
            .sub-sub-selected {
                .item-container {
                    &:nth-child(even) {
                        padding-right: 5px;
                        padding-left: 0;
                    }

                    &:nth-child(odd) {
                        padding-left: 5px;
                        padding-right: 0;
                    }
                }
            }
        }
    }

    p {
        margin-bottom: 0;
    }

    .sub-selected {
        margin-top: 10px;

        .sub-name {
            padding: 5px 0 10px 0;
        }

        .sub-sub-selected {
            display: flex;
            flex-wrap: wrap;

            .item-container {
                width: 50%;
                margin-top: 5px;

                &:nth-child(even) {
                    padding-left: 5px;
                }

                &:nth-child(odd) {
                    padding-right: 5px;
                }

                .selected-item {
                    border: 1px solid #eee;
                    border-radius: 5px;
                    padding: 10px;

                    .subsub-info {
                        display: flex;
                        align-items: center;

                        .adjust {
                            display: flex;
                            align-items: center;

                            .value {
                                color: #3f4254;
                                background: #f6f6f6;
                                padding-left: 5px;
                                padding-right: 5px;
                                height: 30px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }

                            button {
                                background: #f6f6f6;
                                border: 0;
                                color: #3f4254;
                                height: 30px;
                            }
                        }

                        .subsub-name {
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }

    &.offer-selected {
        display: flex;
        flex-wrap: wrap;

        .item-container {
            width: 50%;
            margin-top: 5px;

            &:nth-child(even) {
                padding-left: 2px;
            }

            &:nth-child(odd) {
                padding-right: 2px;
            }

            .selected-item {
                border: 1px solid #eee;
                border-radius: 5px;
                padding: 10px;

                .item-header {
                    border-bottom: 1px solid #eee;
                }
            }
        }
    }
}

.invalid-message {
    display: flex;
    width: 100%;
    margin-top: 0.5rem;
    font-size: 0.925rem;
    color: #e96161;
}

.txtTitleSelect {
    font-size: 12px;
    font-weight: bold;
    color: black;
}