@import "../../../../../theme/assets/sass/components/variables.custom";

.new-ticket {
    .header-dark {
        background-color: $b8ak-dark;

        .modal-title {
            color: $b8ak-dark-inverse;
        }

        .close {
            filter: invert(1);
        }
    }

    .icon-header {
        position: absolute;
        left: 45%;
        top: 20px;
    }
}
