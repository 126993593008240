.filter-datetime {
    padding: 22px 18px;
    border: 1px solid #E6E5EA;
    border-radius: 4px;
    background-color: #FBFBFB;

    .input-search {
        border: 1px solid #E6E5EA;
        border-radius: 6px;

        input {
            height: 34px;
            border: 0;
            padding-left: 0;
        }

        .input-group-text {
            min-height: 34px;
            min-width: 34px;
            padding: 5px 5px;
            background-color: white;
            border: 0;
        }
    }

    .react-datepicker {
        width: 100%;
        margin-top: 20px;
        border: 1px solid #E6E5EA;
        padding: 15px 18px;
        padding-top: 5px;

        .react-datepicker__month-container {
            width: 100%;
            float: none;
        }

        .react-datepicker__navigation {
            top: 15px;
        }

        .react-datepicker__header {
            background-color: transparent;
            border: 0;

            .react-datepicker__current-month {
                color: #5E5873;
                font-size: 14;
            }
        }

        .react-datepicker__month {
            margin: 0;
        }

        .react-datepicker__day-names {
            margin-top: 10px;
        }

        .react-datepicker__day-names,
        .react-datepicker__week {
            display: flex;
            justify-content: space-between;
        }

        .react-datepicker__day-name,
        .react-datepicker__day {
            color: #6E6B7B;
            font-size: 14px;
            height: 26px;
        }

        .react-datepicker__day-name,
        .react-datepicker__day {
            flex: 1
        }

        .react-datepicker__day--keyboard-selected {
            background-color: transparent !important;
            color: #6E6B7B !important;
            // font-weight: bold;
        }

        .react-datepicker__day--selected,
        .react-datepicker__day--selected:hover,
        .react-datepicker__day--keyboard-selected:hover {
            background-color: #FFC20E;
            border-radius: 0.3rem;
        }

        .react-datepicker__day--selected {
            color: white !important;
        }

        .react-datepicker__day--outside-month {
            color: #dfdfdf;
        }
    }

    .date-selected-display {
        height: 37px;
        margin-bottom: 18px;
        box-shadow: 0px 4px 24px rgb(0 0 0 / 6%);

        input {
            padding-left: 5%;
            width: 85%;
            border-radius: 0px 6px 6px 0px;
            border: 1px solid #E6E5EA;
    
            &.rtl {
                border-radius: 6px 0px 0px 6px;
            }
        }

        .icon-date {
            width: 15%;
            height: 37px;
            background: #FFFFFF;
            border: 1px solid #E6E5EA;
            border-radius: 6px 0px 0px 6px;
            text-align: center;
        
            &.rtl {
                border-radius: 0px 6px 6px 0px !important;
            }
        
            svg {
                margin-top: 10px;
                height: 17px !important;
                width: 17px !important;
            }
        }
    }

   
}