.attendance-delivery-container {
    .nav-header {
        margin-bottom: 20px;

        .nav-title {
            color: #5E5873;
            font-size: 14px;
        }

        button {
            border: 0;
            background-color: #00171F !important;
            color: white !important;
            border-radius: 5px;
            height: 38px;
            width: 120px;
            font-size: 14px;
        }
    }

    .filter-section {
        background-color: white;
        padding: 10px;
        border-radius: 6px;
        padding: 16px 23px;
        * {
            font-family: "Tajawal", sans-serif;
        }
        .filter-item {
            &:not(:first-child) {
                margin-top: 15px;
            }

            .label-filter {
                color: #5E5873;
                font-size: 14px;
                margin-bottom: 8;
            }

            .input-filter {}
        }

        .nav-form {
            button {
                background-color: transparent;
                border-radius: 5px;
                height: 38px;
                font-size: 14px;
                color: #00171F;
                border: 1px solid #00171F !important;

                &+button {
                    margin-top: 20px;
                }

                &[type="submit"] {
                    background-color: #00171F !important;
                    color: white !important;
                }
            }
        }
    }

    .content-section {

        .accordion-item {
            background: #FFFFFF;
            box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
            border: 0;

            &:not(:first-child) {
                margin-top: 24px;
            }

            &:last-of-type {
                border-radius: 0;
            }

            .accordion-header {
                background: #D9D9D9;
                height: 37px;
                padding: 1px 0 0 14px;

                .accordion-button {
                    &::after {
                        color: #00171F !important;
                        // background-image: var(--bs-accordion-btn-icon) !important;
                        background-image: url('data:image/svg+xml,<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.4586 7.10016C11.2633 7.30121 10.999 7.41406 10.7236 7.41406C10.4482 7.41406 10.1839 7.30121 9.98857 7.10016L6.24564 3.27885L2.55483 7.10016C2.35949 7.30121 2.09524 7.41406 1.8198 7.41406C1.54436 7.41406 1.28011 7.30121 1.08477 7.10016C0.987045 6.99981 0.909482 6.88042 0.85655 6.74888C0.803619 6.61734 0.776367 6.47624 0.776367 6.33374C0.776367 6.19124 0.803619 6.05015 0.85655 5.9186C0.909482 5.78706 0.987045 5.66767 1.08477 5.56732L5.50539 0.990385C5.60232 0.889208 5.71763 0.808902 5.84468 0.754099C5.97173 0.699296 6.108 0.671081 6.24564 0.671081C6.38327 0.671081 6.51955 0.699296 6.6466 0.754099C6.77365 0.808902 6.88896 0.889208 6.98589 0.990385L11.4586 5.56732C11.5564 5.66767 11.6339 5.78706 11.6869 5.9186C11.7398 6.05015 11.767 6.19124 11.767 6.33374C11.767 6.47624 11.7398 6.61734 11.6869 6.74888C11.6339 6.88042 11.5564 6.99981 11.4586 7.10016Z" fill="black"/></svg>') !important;
                        transition: all 0.3s ease-in-out 0s;
                        transform: rotate(0deg);
                        width: 23px;
                        height: 11px;
                    }

                    &.collapsed::after {
                        transform: rotate(-180deg);
                    }

                    &:not(.collapsed) {
                        box-shadow: none;
                    }
                }

                >button {
                    padding: 8px;
                    background: transparent !important;
                    color: #00171F;
                    font-size: 18px;
                    font-weight: 500;
                }
            }

            .accordion-collapse {
                display: unset !important;

                .accordion-body {
                    >div {
                        //padding: 20px;
                    }

                    overflow: hidden;
                    max-height: 0px;
                    -webkit-transition: all 0.3s ease-in-out 0s;
                    -moz-transition: all 0.3s ease-in-out 0s;
                    -ms-transition: all 0.3s ease-in-out 0s;
                    -o-transition: all 0.3s ease-in-out 0s;
                    transition: all 0.3s ease-in-out 0s;
                    padding: 0;
                }

                &.show {
                    .accordion-body {
                        overflow: unset !important;
                        max-height: 10000px !important;
                    }
                }
            }
        }

        .bus-table {
            background: white;
            padding: 30px 15px 30px 15px;

            .bus-header {
                height: 73px;
                background: #EEEEEE !important;
            }

            .bus-detail {
                position: relative;

                >div {
                    overflow: unset !important;
                }

                .text-blue {
                    color: #00aae5;
                }

                .rdt_TableHead,
                .rdt_TableHeadRow,
                .rdt_TableCol {
                    height: 40px;
                }

                .rdt_TableCol {
                    font-weight: bold;
                    justify-content: space-between;
                    font-size: 14px;
                    #column-evening-shift {
                        margin-left: 20%;
                    }
                }

                .rdt_TableHeadRow {
                    background-color: #FFC20F;
                }

                .form-select-status {
                    padding: 0 10px;
                }

                .customer {
                    color: #39D5AD !important;
                }

                .--bus {
                    justify-content: end !important;
                    margin: 20px;
                }

                .worker-cell {
                    .desc {
                        color: #fb3932
                    }
                }

                .btn-view-order {
                    font-size: 13px;
                    font-weight: bold;
                    &[disabled] {
                        color: #5E5873;
                        text-decoration: none !important;
                    }
                }

                &.--loading {
                    .loading {
                        display: flex;
                    }
                }

                .loading {
                    position: absolute;
                    display: none;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background: rgb(153, 153, 153, .5);
                    justify-content: center;
                    align-items: center;
                }
            }
            .no-data {
                margin-top: 3%;
                margin-bottom: 3%;
            
                img {
                    display: block;
                    margin: auto;
                }
            
                p {
                    font-weight: 500;
                    font-size: 14px;
                    margin-top: 10px;
                    text-align: center;
            
                    color: #EA5455;
                }
            }

        }

        .nav-date {
            height: 50px;
            background-color: #FFC20F;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;

            &.loading {
                background-color: #D1E7DD;
                .date-title {
                    color: #0F5132;
                }
            }

            button {
                background: transparent;
                border: 0;
                .icon-next-date {
                    font-size: 35px;
                    color: black;
                }
            }

            .date-title {
                font-size: 18px;
                color: #00171F;
                font-weight: 500;
            }
        }

        .dropdown-select {
            button {
                width: 100%;
                text-align: left;
                height: 26px;
            }

            .dropdown-toggle {
                padding: 3px 10px !important;
                background-color: #A1A5B7 !important;
                color: white !important;
                font-size: 12px;
                font-weight: bold;
                position: relative;
                width: 120px;

                &:after {
                    content: "";
                    border: solid white;
                    border-width: 0 2px 2px 0;
                    display: inline-block;
                    padding: 3px;
                    vertical-align: middle;
                    transform: rotate(45deg);
                    -webkit-transform: rotate(45deg);
                    margin-left: 8px;
                    margin-top: -4px;
                    right: 8px;
                    top: 10px;
                    position: absolute;
                }

                &.success {
                    background-color: #39D5AD !important;
                }

                &.danger {
                    background-color: #FEE1CE !important;
                    color: #E96161 !important;
                    &:after {
                        border-color: #E96161 !important;
                    }
                }

                &.warning {
                    background-color: #FFC20F !important;
                }

                &.dark {
                    background-color: #00171F !important;
                }

                &[disabled]:not(.success):not(.danger):not(.warning):not(.dark) {
                    background-color: #e7e7e7 !important;
                    border: 1px solid #DADADA;
                    color: #AEAEAE !important;
                    &:after {
                        border-color: #AEAEAE;
                    }
                }
            }

            .dropdown-menu.show {
                padding: 10px;

                .item-unattendant:hover,.item-undelivery:hover {
                    color: #E96161;
                    path {
                        stroke: #E96161;
                    }
                }
                .item-attendance:disabled {
                    background-color: #FFFFFF !important;
                    cursor: pointer;
                }
                .item-unattendant:disabled {
                    background-color: #FFFFFF !important;
                    cursor: pointer;
                }
                .item-attendance:hover, .item-delivery:hover {
                    color: #39D5AD;
                    path {
                        stroke: #39D5AD;
                    }
                }
                .item-undo-delivery:hover {
                    color: #FFA247;
                }
                .dropdown-item {
                    svg {
                        height: 16px !important;
                        width: 16px !important;
                    }
                    &:active {
                        background-color: unset;
                        color: unset;
                    }

                    &.selected {
                        background-color: #F6F6F6;
                    }

                    &.selected-success {
                        color: #39D5AD;
                    }

                    &.selected-danger {
                        color: #E96161;
                    }
                    &.selected-warning {
                        color: #FFA247;
                    }
                }
            }
        }
        
        div:first-child {
            white-space: normal !important;
        }
    }
}

.h-30 {
    height: 30px;
}

.bus-table {
    .accordion {
        .bus-item {
            .bus-header {
                .accordion-button {
                    height: inherit;
                }
            }
        }
    }
}

.bus-table-content {
    width: 100%;
    height: 100%;
}
.form-control:disabled {
    background: rgba(219, 219, 219, 0.2);
    color: #BAC0C2;
    border-color: #EFE8E8;
}
