.filter-choose {
    padding: 22px 18px;
    border: 1px solid #E6E5EA;
    border-radius: 4px;
    background-color: #FBFBFB;

    .input-search {
        border: 1px solid #E6E5EA;
        border-radius: 6px;

        input {
            height: 34px;
            border: 0;
            padding-left: 0;
        }

        .input-group-text {
            min-height: 34px;
            min-width: 34px;
            padding: 5px 5px;
            background-color: white;
            border: 0;
        }
    }

    .choose-container {
        margin-top: 20px;
        border: 1px solid #E6E5EA;
        background-color: #fff;
        border-radius: 4px;
        padding: 11px 21px;

        max-height: 500px;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 6px !important;
            height: 6px !important;
            border-radius: 4px !important;
        }

        &::-webkit-scrollbar-thumb {
            background: #E6E5EA;
            border-radius: 4px;
            height: calc(100% - 60px);
        }

        .options-group {
            &.collapse {
                background-color: red;
            }
        }

        ul {
            list-style: none;
            padding-left: 0;
            margin-bottom: 0;

            li {
                margin-top: 10px;
            }

            ul {
                padding-left: 20px;
            }
        }

        .options-group {
            &.collapsed {
                display: none;
                transition: visibility 0s, opacity 0.5s linear;
            }
        }

        .item-choose {
            display: flex;
            width: 230px;
            flex-direction: row;
            align-items: center;
            font-size: 14px;
            font-weight: 400;
            justify-content: space-between;

            label {
                cursor: pointer;

                &:not(:first-child) {
                    margin-top: 10px;
                }

                :first-child {
                    display: flex;
                    color: #6E6B7B;
                }

                span {
                    white-space: nowrap;
                    width: 160px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                input[type="radio"] {
                    height: 18px;
                    width: 18px;
                    margin-right: 8px;

                    &:checked {
                        -webkit-appearance: none;
                        border-radius: 50%;
                        outline: none;
                        border: 1px solid #FFC20F;
                        position: relative;

                        &::after {
                            content: "";
                            display: block;
                            background-color: #FFC20F;
                            width: 12px;
                            height: 12px;
                            border-radius: 50%;
                            position: absolute;
                            left: 2px;
                            top: 2px;
                        }
                    }
                }

                input[type="checkbox"] {
                    // opacity: 0;
                    height: 18px;
                    width: 18px;
                    border: 1px solid #D8D6DE;
                    border-radius: 3px;
                    margin-right: 8px;

                    &:checked {
                        border: 0;
                        background-color: #FFC20F;
                        accent-color: #FFC20F;

                        &.indeterminate {
                            -webkit-appearance: none;
                            position: relative;

                            &:after {
                                position: absolute;
                                top: 1px;
                                left: 4px;
                                content: '';
                                display: block;
                                width: 10px;
                                height: 10px;
                                border-bottom: 3px solid #3b3b3b;
                            }
                        }
                    }
                }
            }

            .badge {
                background-color: #FFC20F;
                border-radius: 60px;
                color: white;
                font-size: 14px;
                padding: 2px 3px;
                width: 38px;
                line-height: 23px;
            }

            .btn-collapse {
                &.collapsed {
                    &::after {
                        top: -2px;
                        transform: rotate(45deg);
                    }
                }

                &::after {
                    cursor: pointer;
                    content: "";
                    vertical-align: middle;
                    float: right;
                    border-right: 2px solid;
                    border-bottom: 2px solid;
                    height: 10px;
                    width: 10px;
                    transform: rotate(225deg);
                    position: relative;
                    top: 2px;
                    right: 0px;
                }
            }
        }

        li.all-item {
            margin-top: 0;
            >.options-group {
                padding-left: 0px;
            }
        }
    }
}