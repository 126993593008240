.modal-edit-loytal-equation {
    .modal-content {
        height: 349px;
        .modal-header {
            height: 58px;
        }
        .modal-title {
            font-weight: 400;
        }
        .modal-body {
            padding-top: 0;
            padding-bottom: 10px;
        }
        .input-group {
            .input-group-text {
                background-color: #F5F8FA;
            }
        }
        .modal-footer {
            height: 73px;
            padding: 9px 30px 14px;
            background-color: #F6F6F6;
            * {
                margin: 0;
            }
        }
    }
    .justify-content-space-between {
        justify-content: space-between !important;
    }
    .mt-17px {
        margin-top: 17px;
    }
    .fw-17px {
        font-size: 17px !important;
    }
    .fw-18px {
        font-size: 18px !important;
    }
    .apply-expries-day-switcher {
        display: flex;
        flex-direction: row-reverse;
        justify-content: start;
        padding: 0;
        & > input {
            position: relative;
            width: 39.66px;
            height: 23.46px;
            font-weight: 400;
            margin-left: 0;
            margin-right: 0;
            border: 0;
            background-color: #eff2f5;
            background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23ffffff%27/%3e%3c/svg%3e") !important;
        }

        & > input:checked {
            background-color: #ffc20f;
            background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27black%27/%3e%3c/svg%3e") !important;
        }
    }
    .btn-close-loyalty-modal {
        width: 116px !important;
        background-color: #E9EAEB;
        @media (max-width: 400px) {
            width: 100px !important;
        }
    }
    .btn-save-loyalty-modal {
        float: right;
        width: 211px !important;
        background-color: #00171F;
        @media (max-width: 400px) {
            width: 100px !important;
        }
    }
    .svg-disabled {
        path {
            fill: #696868b2;
        }
    }
    &-rtl {
        .btn-save-loyalty-modal {
            float: left;
        }
    }

    &.md-ltr {
        .input-group-text {
            border-radius: 6px 0 0 6px !important;
        }
    }
    &.md-rtl {
        .input-group-text {
            border-radius: 0 6px 6px 0 !important;
        }
    }
}