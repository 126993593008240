.btn-finish {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.content-success {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .image {
    margin-bottom: 20px;
  }
}

.text-worker {
  color: #00171F;
  font-size: 25px;
}