.modal-edit-promocode {
    .justify-content-space-between {
        justify-content: space-between !important;
    }
    .promo-code-type-check {
        //margin-right: 10rem;
    }
    .promocode-switcher {
        display: flex;
        flex-direction: row-reverse;
        justify-content: start;
        padding: 0;
        & > input {
            position: relative;
            width: 3.5rem !important;
            font-weight: 400;
            margin-left: 0;
            border: 0;
            background-color: #eff2f5;
            background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23ffffff%27/%3e%3c/svg%3e") !important;
        }

        & > input:checked {
            background-color: #ffc20f;
            background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27black%27/%3e%3c/svg%3e") !important;
        }
    }
    .custom-promocode-switcher {
        & > input {
            margin-right: 0;
        }
    }
    .promocode-switcher-label-left {
        & > label {
          padding-right: 1rem;
          font-weight: 400; 
        }
    }
    .form-check-input:checked[type=radio] {
        background-image: url("../../../../../../public/assets/images/icons/checked.svg") !important;
        box-shadow: 0px 2px 4px rgba(255, 194, 15, 0.4);        
    }
    .form-check-input[type=radio] {
        width: 18px;
        height: 18px;
        margin-top: 3px;
        padding: 6px;
    }
    .form-check-label {
        font-size: 17px;
        line-height: 21px;
    }
    .mb-20px {
        margin-bottom: 20px;
    }
    .fw-17px {
        font-size: 17px !important;
    }
    .fw-18px {
        font-size: 18px !important;
    }

    &.rtl {
        .promocode-switcher-label-left {
            & > label {
              padding-right: unset;
              padding-left: 5rem;
            }
        }
        span.input-group-text {
            border-radius: 0 6px 6px 0 !important;
        }
    }

    .modal-content label {
        color: #00171F !important;
    }

    span.input-group-text {
        background-color: #F5F8FA;
        border-radius: 6px 0 0 6px !important;;
    }

    label.form-check-label {
        //color: #6E6B7B !important;
    }

    button.btn-close-promo-code {
        width: 116px !important;
        height: 50px !important;
        font-size: 16px;
        color:#00171F;
        @media (max-width: 400px) {
            width: 100px !important;
        }
    }

    button.btn-save-promo-code {
        width: 211px !important;
        height: 50px !important;
        font-size: 16px;   
        @media (max-width: 400px) {
            width: 100px !important;
        }     
    }

    input[type='checkbox'].form-check-input {
        width: 39.36px !important;
        height: 23.46px !important;
    }

    input[type='radio'].form-check-input {
        filter: drop-shadow(0px 2px 4px rgba(255, 194, 15, 0.4));
        border-radius: 18px;
        border-color: rgba(255, 195, 15, 0.137);
    }

    .modal-footer {
        background-color: #F6F6F6;
    }

    .svg-disabled {
        path {
            fill: #696868b2;
        }
    }  
}