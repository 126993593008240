@import 'theme/assets/sass/components/variables.custom';

.worker-section {
  .input-group {
    .input-group-text {
      min-height: 38px;
      height: 38px;
      min-width: 30px;
    }
    input {
      height: 38px;
    }
  }
  .worker-list {
    border: 1px solid #eeeeee;
    border-radius: 4px;
    max-height: 335px;
    overflow-y: scroll;
    overflow: overlay;

    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      opacity: 0.5;
    }

    .worker-item {
      display: flex;
      flex-direction: row;
      padding: 8px;
      align-items: center;
      border-bottom: 1px solid #eeeeee;

      &:hover {
        background-color: #eeeeee;
        cursor: pointer;
        transition: all 0.75s ease;
      }

      &:last-child {
        border-bottom-width: 0px;
      }

      .avatar {
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 6px;
      }

      .rating,
      .info,
      .favorite,
      .radio-check {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .info {
        flex: 1;

        .name {
          color: #464e5f;
          font-size: 14px;
          font-weight: 600;
        }

        .start-date {
          color: #b5b5c3;
          font-size: 13px;
        }
      }

      .rating {
        padding: 0 10px;

        > span {
          color: #b5b5c3;
          font-size: 12px;
        }
      }

      .favorite {
        align-items: center;
        padding: 0 10px;

        .fav-icon-wrap {
          height: 30px;
          width: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .fav-icon {
          background: url('../../../../public/assets/images/icons/favorite.svg') no-repeat;
          height: 18px;
          width: 20px;

          &.favorited {
            background: url('../../../../public/assets/images/icons/favorite-1.svg') no-repeat;
            height: 18px;
            width: 20px;
          }

          &:active {
            background: url('../../../../public/assets/images/icons/favorite-active.svg') no-repeat !important;
          }

          &:hover {
            background: url('../../../../public/assets/images/icons/favorite-hover.svg') no-repeat;
          }
        }

        > span {
          color: #464e5f;
          font-size: 12;
          font-weight: 600;
        }
      }

      .radio-check {
        align-items: center;
        padding: 0 20px;

        .form-check-input:checked[type='radio'] {
          background-image: unset !important;
          box-shadow: 0px 2px 4px rgba(255, 194, 15, 0.4);
        }

        .form-check-input {
          margin-top: 5px;
          width: 15px !important;
          height: 15px !important;
        }

        .form-check-label {
          display: none;
        }
      }
    }
  }
}

.filter-container {
  .filter-title {
    margin: 0 10px 0 0;
    white-space: nowrap;
  }
  .custom-col-3{
    width: 23%;
  }
  .custom-col-4{
    width: 37%;
    padding: 0 0.5rem;
  }
  .custom-col-5{
    width: 40%;
  }
  @media (max-width: 768px) {
    .filter-title {
      min-width: 58px;
    }
  }
  .filter-input-disabled{
    input{
      left: unset;
    }
  }
  .filter-option {
    cursor: pointer;
    color: #464e5f;
    span{
      white-space: nowrap;
    }
  }
  .filter-option:hover {
    color: #39d5ad;
    svg {
      path {
        fill: #39d5ad;
      }
      &.stroke-color {
        path {
          stroke: #39d5ad;
          fill: unset !important;
        }
      }
    }
  }
  .start-date-picker {
    input {
      width: 100%;
      height: 38px;
      border-radius: 4px;
      border: 1px solid #eeeeee;
      padding: 4px 8px;
      font-weight: 600;
      color: #a1a5b7;
      cursor: default;
      caret-color: transparent;

      &::placeholder {
        color: #a1a5b7;
      }

      &:focus-visible {
        outline: none;
        border-color: rgb(181, 181, 195);
      }

      &:disabled {
        background: rgba(232, 232, 232, 0.7);
      }
    }
  }

  $item-width: 42px;
  $item-height: 36px;

  .react-datepicker {
    border-radius: 4px;
    font-family: 'Tajawal', sans-serif;
    border: 1px solid #eeeeee;
    filter: drop-shadow(0px 20px 40px rgba(0, 0, 0, 0.2));

    .react-datepicker__triangle {
      &::before {
        border-bottom-color: #eeeeee;
      }
    }

    button.react-datepicker__navigation {
      margin-top: 4px;

      > span::before {
        border-color: #00171f;
      }
    }

    .react-datepicker__header {
      border: 0;
      border-radius: 0;
      background: transparent;
      padding: 0;

      .react-datepicker__current-month {
        line-height: 32px;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: 500;
      }

      .react-datepicker__day-names {
        .react-datepicker__day-name {
          width: $item-width;
          line-height: $item-height;
          font-size: 16px;
        }
      }
    }

    .react-datepicker__month-container {
      .react-datepicker__current-month {
        background: rgba(238, 238, 238, 0.52);
      }
    }

    .react-datepicker__week {
      .react-datepicker__day {
        width: $item-width + 4;
        border: 1px solid transparent;
        line-height: $item-height;
        font-size: 15px;
        font-weight: 400;
        position: relative;
        background: transparent;
        z-index: 1;
        margin: 0px;

        &::before {
          content: '';
          display: block;
          position: absolute;
          width: 30px;
          height: 30px;
          top: calc(50% - 15px);
          left: calc(50% - 15px);
          border-radius: 50%;
          z-index: -1;
        }

        &.react-datepicker__day--outside-month {
          // color: #AAAAAA;
        }

        &:hover:not(.react-datepicker__day--disabled) {
          border-radius: 50%;
          background: transparent;

          &::before {
            background: #f0f0f0;
          }
        }

        &.react-datepicker__day--highlighted {
          background: #fff3cf !important;
          border-radius: 0px;
          color: #00171f;
        }

        &.react-datepicker__day--highlighted.react-datepicker__day--highlighted--last--vertical {
          border-radius: 0% 0% 50% 50%;
        }

        &.react-datepicker__day--selected:not(.react-datepicker__day--disabled).react-datepicker__day--highlighted.react-datepicker__day--selected--first {
          border-radius: 50% 0% 0% 50%;
          background: #fff3cf !important;
        }
        &.react-datepicker__day--selected:not(.react-datepicker__day--disabled).react-datepicker__day--highlighted.react-datepicker__day--selected--first--rtl {
          border-radius: 0% 50% 50% 0%;
          background: #fff3cf !important;
        }

        &.react-datepicker__day--selected:not(.react-datepicker__day--disabled).react-datepicker__day--highlighted.react-datepicker__day--selected--vertical {
          border-radius: 50% 50% 0% 0%;
          background: #fff3cf !important;
        }

        &.react-datepicker__day--selected:not(.react-datepicker__day--disabled).react-datepicker__day--highlighted {
          border-radius: 0px;
          background: #fff3cf !important;
        }

        &.react-datepicker__day--selected--one:not(.react-datepicker__day--disabled).react-datepicker__day--highlighted {
          border-radius: 0px;
          background: transparent !important;
        }

        &.react-datepicker__day--selected:not(.react-datepicker__day--disabled).react-datepicker__day--highlighted.react-datepicker__day--selected--last {
          border-radius: 0% 50% 50% 0%;
          background: #fff3cf !important;
        }

        &.react-datepicker__day--selected:not(.react-datepicker__day--disabled).react-datepicker__day--highlighted.react-datepicker__day--selected--last--rtl {
          border-radius: 50% 0% 0% 50%;
          background: #fff3cf !important;
        }

        &.react-datepicker__day--selected:not(.react-datepicker__day--disabled).react-datepicker__day--showing {
          &::before {
            // border: 1px #00171F solid;
          }
        }

        &.react-datepicker__day--selected:not(.react-datepicker__day--disabled) {
          border-radius: 50%;
          color: #00171f;

          &::before {
            background: $primary;
          }
        }

        &.react-datepicker__day--keyboard-selected {
          background: white;
          border: 0;
          color: #000;
        }

        &.react-datepicker__day--disabled {
          color: #ccc !important;
        }

        &.react-datepicker__day--excluded {
          color: #ccc;
        }

        &:focus-visible,
        &:focus,
        &:visited {
          outline: unset;
        }
      }
    }
  }

  &.rtl {
    .filter-title {
      margin: 0 0 0 10px !important;
    }
  }
}
