.list-item--select{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  max-height: 200px;
  overflow-y: scroll;
  flex-wrap: wrap;
  .item{
    padding: 10px 20px;
    background: #00171F;
    color: #FFFFFF;
    border-radius: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
    @media (max-width: 786px) {
      margin-right: 3px;
    }
    .name{
      padding: 0 15px;
    }
    .close{
      cursor: pointer;
    }
  }
}

label.select-toggle{
  width: max-content;
  input{
    margin-right: 10px;
  }
}