@import "theme/assets/sass/components/variables.custom";

.drawer-create-order {
    visibility: hidden;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 9999;
    transition: visibility 0.3s linear;

    .invalid-message {
        display: flex;
        width: 100%;
        font-size: 0.925rem;
        color: black;
        margin-top: 10px;
        font-weight: bold;
    }

    input:not([id^="react-select"]):not([type="radio"]) {
        min-height: 34.3px;

        &:focus {
            color: #00171f;
            background-color: #ffffff;
            border: 1px solid #b5b5c3 !important;
            outline: 0 !important;
            box-shadow: unset !important;
        }
    }

    .input-group {
        .form-select {
            padding: 5px;
        }
    }


    .input-group-text {
        padding: 5px 5px;
    }

    .input-group .input-group-text {
        min-height: 30px;
        min-width: 30px;
    }

    .form-control {
        padding: 5px 5px;
        font-family: 'Tajawal';
    }

    .form-label {
        font-size: 14px;

        &:empty {
            height: 13px;
            line-height: 13px;
            margin: 0;
            display: block;
        }
    }

    &.--open {
        visibility: visible !important;

        .backdrop {
            opacity: 1;
        }

        .drawer-content {
            transform: translate3d(0, 0, 0) !important;
        }
    }

    &.--loading {
        .form-loading {
            display: flex !important;
        }
    }

    .backdrop {
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transition-property: opacity;
        transition-duration: 0.3s;
        opacity: 0;
    }

    .drawer-content {
        min-width: 865px;
        position: absolute;
        right: 0;
        top: 85px;
        bottom: 0;
        left: 55%;
        background: white;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
        -webkit-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;

        .customer-phone {
            .input-group-text {
                border-radius: 0 0.475rem 0.475rem 0;
            }
        }

        .customer-input {
            .input-group-text {
                border-radius: 0 0.475rem 0.475rem 0;
            }
        }

        &.rtl {
            right: 57% !important;
            left: 0px !important;
            -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);

            .drawer-body {
                .accordion-header {
                    padding: 1px 14px 0 0 !important;
                }

                .checker {
                    margin-right: 10px !important;
                }
            }

            .drawer-header {
                padding: 0 28px 0 23px !important;
            }

            .customer-phone {
                .input-group-text {
                    border-radius: 0.475rem 0 0 0.475rem;
                }
            }

            .customer-input {
                .input-group-text {
                    border-radius: 0.475rem 0 0 0.475rem;
                }
            }

        }

        @media all and (max-width: 1440px) {
            left: 35%;

            &.rtl {
                right: 35% !important;
                left: 0% !important;
            }
        }

        @media all and (max-width: 1024px) {
            min-width: 680px;
            left: 30%;

            &.rtl {
                min-width: 680px;
                right: 30% !important;
                left: 0% !important;
            }
        }

        @media all and (max-width: 768px) {
            min-width: 0px;
            left: 0;
            top: 0;          

            &.rtl {
                min-width: 0px;
                right: 0 !important;
                left: 0 !important;  
            }
        }

        /* position: relative; */

        .form-loading {
            position: absolute;
            display: none;
            // display: flex;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgb(153, 153, 153, .5);
            justify-content: center;
            align-items: center;
        }

        .drawer-header {
            background: $primary;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0 23px 0 28px;
            height: 73px;

            p {
                flex: 1;
                margin-bottom: 0 !important;
                // padding: 8px;
                font-size: 18px;
                color: #00171F;
                font-weight: 400;
            }

            button {
                background: transparent;
                border: 0;

                i {
                    color: #00171F;
                    font-size: 19.71px;
                }
            }
        }

        .drawer-body {
            padding: 28px;
            overflow: auto;
            height: calc(100% - 60px);
            background-color: #FBFBFB;

            .accordion-item {
                background: #FFFFFF;
                box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
                border: 0;

                &:not(:first-child) {
                    margin-top: 24px;
                }

                &:last-of-type {
                    border-radius: 0;
                }

                .accordion-header {
                    background: #D9D9D9;
                    height: 37px;
                    padding: 1px 0 0 14px;

                    .accordion-button {
                        &::after {
                            color: #00171F !important;
                            // background-image: var(--bs-accordion-btn-icon) !important;
                            background-image: url('data:image/svg+xml,<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.4586 7.10016C11.2633 7.30121 10.999 7.41406 10.7236 7.41406C10.4482 7.41406 10.1839 7.30121 9.98857 7.10016L6.24564 3.27885L2.55483 7.10016C2.35949 7.30121 2.09524 7.41406 1.8198 7.41406C1.54436 7.41406 1.28011 7.30121 1.08477 7.10016C0.987045 6.99981 0.909482 6.88042 0.85655 6.74888C0.803619 6.61734 0.776367 6.47624 0.776367 6.33374C0.776367 6.19124 0.803619 6.05015 0.85655 5.9186C0.909482 5.78706 0.987045 5.66767 1.08477 5.56732L5.50539 0.990385C5.60232 0.889208 5.71763 0.808902 5.84468 0.754099C5.97173 0.699296 6.108 0.671081 6.24564 0.671081C6.38327 0.671081 6.51955 0.699296 6.6466 0.754099C6.77365 0.808902 6.88896 0.889208 6.98589 0.990385L11.4586 5.56732C11.5564 5.66767 11.6339 5.78706 11.6869 5.9186C11.7398 6.05015 11.767 6.19124 11.767 6.33374C11.767 6.47624 11.7398 6.61734 11.6869 6.74888C11.6339 6.88042 11.5564 6.99981 11.4586 7.10016Z" fill="black"/></svg>') !important;
                            background-size: unset;
                            transition: all 0.3s ease-in-out 0s;
                            transform: rotate(0deg);
                            width: 13px;
                            height: 11px;
                        }

                        &.collapsed::after {
                            transform: rotate(-180deg);
                        }

                        &:not(.collapsed) {
                            box-shadow: none;
                        }
                    }

                    >button {
                        padding: 8px;
                        background: transparent !important;
                        color: #00171F;
                        font-size: 18px;
                        font-weight: 500;
                        font-family: 'Tajawal';
                    }
                }

                .accordion-collapse {
                    display: unset !important;

                    .accordion-body {
                        >div {
                            padding: 20px;
                        }

                        overflow: hidden;
                        max-height: 0px;
                        -webkit-transition: all 0.3s ease-in-out 0s;
                        -moz-transition: all 0.3s ease-in-out 0s;
                        -ms-transition: all 0.3s ease-in-out 0s;
                        -o-transition: all 0.3s ease-in-out 0s;
                        transition: all 0.3s ease-in-out 0s;
                        padding: 0;
                    }

                    &.show {
                        .accordion-body {
                            overflow: unset !important;
                            max-height: 10000px !important;
                        }
                    }
                }
            }

            .btn-submit {
                width: 100%;
                background: #00171F;
                color: white;
                margin-top: 28px;
            }

            .btn-reset {
                width: 100%;
                color: #00171F;
                border: 1px solid #00171F !important;
                margin-top: 28px;
            }

            .form-section {
                &.summary-section {
                    .accordion-header {
                        background: #E4C66C;
                    }
                }

                .section-title {
                    color: #00171F;
                    font-size: 17px;
                    margin-top: 0px;
                }

                .sub-title {
                    color: #00171F;
                    font-size: 14px;
                    font-weight: 400;
                    margin: 21px 0 8px 0;
                }
            }

            .need-service-section {
                .number-worker--input {

                    min-height: 34px;

                }

                .btn-adjust {
                    width: 34px;
                    height: 34px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    i {
                        padding: 0;
                    }

                    &.minus {
                        i {
                            color: $primary !important;
                        }

                        border: 1px solid $primary !important;
                        background: white !important;
                        margin: 0px 10px 0px 10px;
                    }

                    &.plus {
                        background: $primary !important;
                        color: white !important;
                    }
                }

                .price-row {
                    .none-padding-col {
                        flex: 1;
                        padding: 0px;
                    }

                    .none-padding-col--left {
                        flex: 1;
                        padding-left: 0px;
                    }

                    .none-padding-col--right {
                        flex: 1;
                        padding-right: 0px;
                    }

                    input {
                        min-height: 35px;
                        border-radius: 0;
                    }

                }
            }

            .worker-section {
                .worker-list {
                    border: 1px solid #EEEEEE;
                    border-radius: 4px;
                    max-height: 335px;
                    overflow-y: scroll;

                    .worker-item {
                        display: flex;
                        flex-direction: row;
                        padding: 8px;
                        align-items: center;
                        border-bottom: 1px solid #EEEEEE;

                        &:last-child {
                            border-bottom-width: 0px;
                        }

                        .avatar {
                            width: 50px;
                            height: 50px;
                            object-fit: cover;
                            border-radius: 6px;
                        }

                        .rating,
                        .info,
                        .favorite,
                        .radio-check {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                        }

                        .info {
                            flex: 1;

                            .name {
                                color: #464E5F;
                                font-size: 14px;
                                font-weight: 600;
                            }

                            .start-date {
                                color: #B5B5C3;
                                font-size: 13px;
                            }
                        }

                        .rating {
                            padding: 0 10px;

                            >span {
                                color: #B5B5C3;
                                font-size: 12px;
                            }
                        }

                        .favorite {
                            align-items: center;
                            padding: 0 10px;

                            .fav-icon-wrap {
                                height: 30px;
                                width: 30px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }

                            .fav-icon {
                                background: url("../../../../../public/assets/images/icons/favorite.svg") no-repeat;
                                height: 18px;
                                width: 20px;

                                &.favorited {
                                    background: url("../../../../../public/assets/images/icons/favorite-1.svg") no-repeat;
                                    height: 18px;
                                    width: 20px;
                                }

                                &:active {
                                    background: url("../../../../../public/assets/images/icons/favorite-active.svg") no-repeat !important;
                                }

                                &:hover {
                                    background: url("../../../../../public/assets/images/icons/favorite-hover.svg") no-repeat;
                                }

                            }

                            >span {
                                color: #464E5F;
                                font-size: 12;
                                font-weight: 600;
                            }
                        }

                        .radio-check {
                            align-items: center;
                            padding: 0 20px;

                            .form-check-input:checked[type=radio] {
                                background-image: unset !important;
                                box-shadow: 0px 2px 4px rgba(255, 194, 15, 0.4);
                            }

                            .form-check-input {
                                margin-top: 5px;
                                width: 15px !important;
                                height: 15px !important;
                            }

                            .form-check-label {
                                display: none;
                            }
                        }
                    }

                    .worker-item:hover {
                        background-color: #EEEEEE;
                        cursor: pointer;
                        transition: all 0.75s ease;
                    }
                }
            }

            .summary-section {
                box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
                border: none;

                .package-details {
                    background: #F6F6F6;
                    padding: 10px;

                    p {
                        margin: 0;
                    }

                    .package-title {
                        color: #7D8699;
                        font-size: 14px;
                    }

                    .service {
                        color: #293340;
                        font-size: 15px;
                        padding-top: 8px;
                        padding-bottom: 8px;
                    }

                    .visit {
                        color: #7D8699;
                        font-size: 12px;
                    }

                    .person {
                        border: 1px solid rgba(0, 0, 0, 0.125);
                        border-left-width: 0;
                        border-right-width: 0;
                        border-bottom-width: 0;

                        .person-item {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            padding: 5px;
                            border-top: 1px solid #A3A3A4;

                            &:first-child {
                                border: 0;
                            }

                            .avatar {
                                width: 50px;
                                height: 50px;
                                object-fit: cover;
                                border-radius: 6px;
                            }

                            .info {
                                .name {
                                    color: #464E5F;
                                    font-size: 14px;
                                    font-weight: 600;
                                }

                                .service,
                                .start-date {
                                    color: #B5B5C3;
                                    font-size: 13px;
                                }
                            }
                        }
                    }
                }

                .visit-details {
                    background: #F6F6F6;
                    padding: 10px;
                    margin-top: 10px;

                    .visit-title {
                        color: #293340;
                        font-size: 14px;
                        font-weight: 700;
                        margin: 0;
                    }

                    table {
                        width: 100%;

                        th {
                            color: #3F4254;
                            font-size: 13px;
                            font-weight: 500;
                            // text-align: center;
                        }

                        td {
                            color: #3F4254;
                            font-size: 12px;
                        }

                        tbody {
                            border: 1px solid #EFF2F5;
                            border-left-width: 0;
                            border-right-width: 0;
                            border-bottom-width: 0;
                        }
                    }

                    .show-more {
                        padding: 0 !important;
                        color: #ffc20f;
                        font-size: 14px;
                    }

                    .show-more:hover {
                        color: #f1bc00;
                    }
                }

                .discount {
                    padding: 10px 0;

                    .discount-item {
                        display: flex;
                        flex-direction: row;
                        border: 1px solid #BAC0C2;
                        border-radius: 5px;
                        margin-top: 5px;
                        padding: 5px;

                        &:first-child {
                            margin-top: 0;
                        }

                        .discount-label {
                            flex: 1;
                            color: #39D5AD;
                        }

                        .btn-remove {
                            background: transparent;
                            border: 0;
                        }
                    }
                }

                .payment-details {
                    background: #F6F6F6;
                    padding: 10px;
                    margin-top: 10px;

                    p {
                        margin-bottom: 0;
                    }

                    .payment-title {
                        color: #293340;
                        font-size: 14px;
                        font-weight: 700;
                        margin: 0;
                    }

                    .bill-cate {
                        margin-top: 5px;

                        &.border-item {
                            border: 1px solid rgba(0, 0, 0, 0.125);
                            border-left-width: 0;
                            border-right-width: 0;
                            border-bottom-width: 0;
                        }
                    }

                    .bill-item {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        .bill-label {
                            color: #7D8699;
                            font-size: 12px;
                        }

                        &.total {
                            .bill-label {
                                color: #00171f;
                            }
                        }

                        .bill-price {
                            color: #00171F;
                            font-size: 12px;
                        }
                    }
                }

                .offer-selected {
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 10px;

                    .item-container {
                        width: 100%;
                        margin-top: 5px;

                        &:nth-child(even) {
                            padding-left: 2px;
                        }

                        &:nth-child(odd) {
                            padding-right: 2px;
                        }

                        .selected-item {
                            border: 1px solid #eee;
                            border-radius: 5px;
                            padding: 10px;

                            .item-header {
                                border-bottom: 1px solid #eee;
                            }
                        }
                    }

                }
            }
        }
    }
}

.create-order-success {
    padding: 20px;
}

.message {
    font-size: 25px;
    font-weight: 400;
    padding: 20px 0;
}

.nav {
    display: flex;
    justify-content: space-between;

    button {
        background: transparent;
        border: 0;
        font-size: 16px;
        height: 50px;
        border-radius: 8px;
        width: 180px;

        &.btn-add-new {
            color: white;
            background: #00171F;
        }

        &.btn-close-modal {
            color: #707070;
            background: #E8E8E8;
        }
    }
}