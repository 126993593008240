
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
.select-service {
    display: block;
    width: 100%;
    font-weight: 500;
    line-height: 1.5;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #e4e6ef;
    appearance: none;
    border-radius: 0.475rem;
    
    .tag-list {
        padding: 0;
        margin: 0.95rem 0 0.25rem 0;
        .placeholder {
            color: #bac0c2 !important;
        }
        .tag-item {
            display: inline-block;
            margin-left: 4px;
            color: black;
            .tag {
                border-radius: 5px;
                background-color: #f4f4f4;
                border: 1px solid #e9e9e9;
                padding: 2px 0 2px 2px;
                display: inline-block;
                .tag-remove {
                    color: #a0a0a0;
                    font-size: 75%;
                    line-height: 100%;
                    cursor: pointer;
                    background-color: transparent;
                    border: none;
                    outline: none;
                }
            }
        }
    }
    .node.focused {
        background-color: #f4f4f4;
    }
    ul {
        list-style-type: none !important;
    }
    .dropdown-content {
        font-weight: 400;
        font-size: 14px;
        width: 100%;
        margin-top: 13px;
        border-radius: 5px;
        z-index: 9;
        position: absolute;
        padding: 4px;
        z-index: 1;
        background: #fff;
        border-top: 1px solid rgba(0,0,0,.05);
        box-shadow: 0 5px 8px rgb(0 0 0 / 15%);
        .search {
            height: 40px;
            width: 100%;
            border: none;
            border-bottom: 1px solid #ccc;
            outline: none;
        }
        ul {
            padding: 0;
            .infinite-scroll-component {
                max-height: 300px;
                overflow: scroll;
                
                .checkbox-item {
                    width: 17px;
                    height: 17px;
                    margin-left: 5px;
                    margin-right: 5px;
                    vertical-align: middle;
                    margin: 0 4px 0 0;
                }
            }
            li {
                display: list-item;
                text-align: -webkit-match-parent;
                padding-left: 0;
            }
            .toggle {
                font-size: 23px;
                margin-right: 5px;
                padding: 5px;
                color: #7d8699; 
            }
            .toggle.collapsed:after {
                content: "+";
            }
            .toggle.expanded:after {
                content: "-";
            }
            .toggle.collapsed {
                writing-mode: vertical-lr;
                -webkit-transform: rotate(44deg);
            }
        }
        .node {
            list-style: none;
            white-space: nowrap;
            padding: 4px;
        }
    }
}
.flex-root {
    flex: 1;
}
div {
    display: block;
}