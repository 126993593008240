.form-multi-select {
    .dropdown-heading {
        justify-content: space-between;
    }

    .dropdown-heading-value {
        max-width: 600px;
    }
}

.btn-service {
    border-radius: 30px;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 10px;
}

.modal {
    z-index: 1050 !important;
}