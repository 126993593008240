@import '../../../../../theme/assets/sass/components/variables.custom';

.btn-create-promo-code {
  width: 193px !important;
  height: 38px;
  padding: inherit !important;
  font-weight: 700;
}

.btn-edit-row-promo-code {
  width: 39px !important;
  height: 39px !important;
  padding: inherit !important;
  background-color: #EEEEEE !important;

  svg {
    margin-top: -4px;
    path {
      fill: #A1A5B7;
    }
  }
}

.promo-code-table {
  position: relative;
  padding: 0;
  border-radius: 4px;
  * {
    font-family: 'Tajawal', sans-serif !important;
  }

  &>div {
    width: 100%;
    padding: 0;

    &:first-child {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      border: 1px solid #eeeeee;
      border-top: none;
      height: 530px;

      .rdt_Table {
        width: 100%;
        min-width: 800px;

        &>.rdt_TableHead {
          height: 50px;

          &>.rdt_TableHeadRow {
            height: 50px;
            min-height: 0;

            &>.rdt_TableCol {
              background-color: var(--bs-primary);
              color: var(--bs-gray-900);
              justify-content: start;

              &:nth-child(1) {
                justify-content: center;
              }

              &:nth-child(2) {
                justify-content: center;
              }

              &:nth-child(3) {
                justify-content: center;
              }

              &:nth-child(4) {
                justify-content: center;
              }

              &:nth-child(5) {
                justify-content: center;
              }
            }
          }
        }

        &>.rdt_TableBody {
          overflow: overlay;
          height: 480px;
          display: block;

          &>.rdt_TableRow {
            height: 80px;

            &>.rdt_TableCell {
              padding: 0;
              height: 80px;
              padding: 10px 0;

              &>p {
                color: var(--bs-gray-900);
                font-size: 14px;
                font-weight: 500;
                margin: 0;
                width: 100%;
                position: absolute;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
          &>.rdt_TableRow:last-of-type {
            border-bottom-style: solid;
            border-bottom-width: 1px;
            border-bottom-color: rgba(0,0,0,.12);
          }
        }
      }
    }

    &:last-child>div {
      border: none !important;
      justify-content: end !important;
      .page-item.active {
        .page-link{
            color: #00171F;
        }
      }
    }
  }
}